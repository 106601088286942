.wrapper {
  display: flex;
  justify-content: center;

  align-items: center;
  max-width: 1170px;
  width: 100%;
  margin: 60px auto 0;

  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 40px;
    height: 50px;
    width: 170px;
    background: #ffffff;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #3d3177;
    transition: all 0.3s;

    &:hover,
    &.active {
      background: #ffa900;
    }
  }
}

@media (max-width: 767px) {
  .wrapper {
    flex-wrap: wrap;

    .tab {
      padding: 20px 30px;
      margin: 10px;
    }
  }
}
