.checkBox {
  display: flex;
  justify-content: center;
}
.detailWrap {
  margin: 20px auto;
  max-width: 1200px;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 18px;
  cursor: grab;
}
.scrollWrap {
  @media (max-width: 1120px) {
    width: 100%;
    position: relative;
    display: flex;
    display: -webkit-flex;
    -webkit-overflow-scrolling: touch;
  }
}

.tableWrap {
  @media (max-width: 1120px) {
    min-width: 1120px;
  }
  @media (max-width: 768px) {
    min-width: 865px;
  }
  @media (max-width: 430px) {
    min-width: 550px;
  }
}

.table {
  color: #ffffff;
  text-transform: uppercase;
  border: 0 none;
  width: 100%;
  border-collapse: collapse;
}
caption {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
}
colgroup {
  display: table-column-group;

  .col_team {
    width: 6.7%;
  }
  .col_space {
    width: "*";
  }
  .col_placement {
    width: 12%;
  }
  .col_kills {
    width: 17.5%;
  }
  .col_winLose {
    width: 12%;
  }
  .col_total {
    width: 17.5%;
  }
  @media (max-width: 768px) {
    .col_team {
      width: 10%;
    }
  }
  @media (max-width: 430px) {
    .col_team {
      width: 15%;
    }
  }
}
col {
  display: table-column;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.table tr:last-child {
  border-bottom: 0;
}
.table tr {
}

.table tr th:first-child {
  text-align: left;
  padding-left: 90px;
}
.table tr th {
  padding-left: 8px;
  padding-right: 8px;
}
.table tr th {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 16px;
  height: 78px;
}
.table tr th:first-child {
  text-align: left;
  padding-left: 90px;
}
.table tr th:last-child {
  padding-right: 26px;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
// placement
// .table tr:nth-of-type(1) td:first-child {
//   color: #f2a900;
// }
// .table tr:nth-of-type(2) td:first-child {
//   color: #f2a900;
// }
// .table tr:nth-of-type(3) td:first-child {
//   color: #f2a900;
// }

@media (max-width: 1600px) {
  .table tr td:first-child {
    font-size: 16px;
  }
}
.table tr .name {
  text-align: left;
  text-transform: initial;
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
  }
}
.table tr td {
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 400;
  font-size: 16px;

  height: 70px;
  text-align: center;
}
.table tr td input {
  font-weight: 400;
  font-size: 16px;
  caret-color: transparent;
  text-align: center;
}
.table tr td:last-child {
  padding-right: 26px;
}
.table tr .name .logo {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  margin-right: 8px;
}
.table tr .name .logo img {
  width: 100%;
  display: block;
}
.sendResultWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.roundChangeInputTime {
  background: #9a1ec779;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 18px;
  color: white;
  margin-bottom: 15px;
}
.roundChangeTitle {
  font-size: 18px !important;
  color: white !important;
  margin: 15px 0px;
}
.roundChangeSelect {
  margin-bottom: 25px;
  background: #9a1ec779;
  font-size: 18px !important;
  color: white !important;
  padding: 0 10px;
  border-radius: 5px;
}

.selectItem {
  background: #9a1ec7 !important;
}
.modal__wrapper {
  background: #1818180e;
  padding: 15px 15px 60px 15px;
  box-sizing: border-box;
}
.modal {
  width: 500px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 20px;
  border: 2px #707070 solid;
  ul {
    background: #b01717eb !important;
  }
}
.modalContainer {
  display: flex;
  flex-direction: column;
}
.modal__header__about {
  height: 70px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal__header__about__title {
  padding-left: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #ffffff;
}

.modal__title {
  display: block;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.modal__info {
  display: flex;
  flex-direction: column;
}
.modal__info__value {
  display: flex;
}
.modal__info__value__first {
  width: 125px;
  margin-left: 15px;
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: start;
}
.modal__info__value__second {
  width: 250px;
  margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  line-height: 30px;
  text-align: start;
}
.modal__title__header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.modal__title__buttons {
  display: flex;
  flex-direction: column;
}
.modalText {
  padding-top: 20px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.modalText:hover {
  color: orange;
}
@media (max-width: 768px) {
  .modal {
    width: 400px;
  }
}

@media (max-width: 425px) {
  .modal {
    width: 300px;
  }
}
