.wrapper {
  padding: 20px;
}

.regPeriod {
  display: -ms-grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-template-columns: 50px auto;
  gap: 16px;
  padding: 14px 0 14px 14px;
  border-radius: 16px;
  margin-bottom: 16px;
}

.tourDetails {
  margin-bottom: 16px;

  padding: 11px;
  border-radius: 10px;
  display: -ms-grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  & > div {
    &:first-of-type {
      & > img {
        width: 62px;
        height: auto;
        border-radius: 10px;
      }
    }

    &:last-of-type {
      & > h1 {
        font-size: 16px;
        font-weight: 400;
        border-bottom: 1px solid grey;
        padding-bottom: 5px;
        margin: 0 auto;
        margin-bottom: 5px;
      }

      & > p {
        font-size: 13px;
        font-weight: 300;
      }
    }
  }
}
.tournament__organizer {
  margin-bottom: 16px;

  padding: 11px;
  border-radius: 10px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-template-columns: 65px auto;
  gap: 16px;

  & > div {
    &:first-of-type {
      & > img {
        width: 62px;
        height: auto;
        border-radius: 10px;
      }
    }

    &:last-of-type {
      & > h1 {
        font-size: 16px;
        font-weight: 400;
        border-bottom: 1px solid grey;
        padding-bottom: 5px;
        margin: 0 auto;
        margin-bottom: 5px;
      }

      & > p {
        font-size: 13px;
        font-weight: 300;
      }
    }
  }
}
.tourRules {
  border-radius: 10px;
  padding: 10px 18px;
  margin-bottom: 16px;

  & > h1 {
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid grey;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  & > ul {
    li {
      font-size: 15px;
      font-weight: 300;
      margin: 10px;
    }
  }

  & > a {
    color: #f7a01d;
    font-size: 11px;
    font-weight: 300;
    display: block;
    width: max-content;
    margin-left: auto;
    margin-top: 5px;
  }
}

.cashPrize {
  border-radius: 10px;
  padding: 10px 18px;
  margin-bottom: 25px;

  & > h1 {
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid grey;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  & > ul {
    li {
      font-size: 13px;
      line-height: 16px;
    }
  }
}

.tourGame {
  border-radius: 10px;
  padding: 10px 18px;
  margin-bottom: 16px;

  & > h1 {
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid grey;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  & > ul {
    li {
      margin: 10px 0px;
    }
  }
}

.btnWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.wrapperTournaments {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
}

.aboutTournaments {
  padding: 20px;
  margin: 0 auto;
  box-sizing: border-box;

  border-radius: 10px;
}

.aboutTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 12px;
  border-bottom: 1px solid gray;
}

.organizerTeam {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: normal;
  padding: 16px;
  border: 3px solid #212121;
  border-radius: 26px;
}

.organizerImage {
  margin-right: 16px;
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.gamesContainer {
  &::-webkit-scrollbar {
    height: 0;
    min-height: 0;
  }
}
