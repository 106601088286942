.bracket {
  margin-top: 20px;
}
.FFAContainer {
  display: flex;
  background: #424243;
  max-height: 1000px;
  margin: 18px 0;
  padding-bottom: 18px;
  cursor: grab;
  overflow-x: scroll;
}
.container {
  border-radius: 10px;
  overflow-x: auto;
  padding: 12px 24px 15px;

  &::-webkit-scrollbar {
    height: 0;
  }
}

.list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 25px;
  min-width: 550px;
  overflow-x: auto;
  overflow-y: hidden;
}

.listItem {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: -0.55px;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 5px;
  border-bottom: 1px solid transparent;
  transform: translateY(3px);
  cursor: pointer;
  .listItemTitle {
    width: 70px;
  }
  &:last-of-type {
    margin-right: 24px;
  }
}

.active {
  border-color: var(--yellow);
}
