.matchesItemContainer {
  margin: 20px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  max-width: 500px;
  padding: 20px;
  cursor: pointer;
  width: 100%;
  transition: all 0.3s;
}

.title {
  font-size: 16px;
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.resultContainer {
  display: flex;
  min-height: 200px;

  /* flex-direction: row-reverse; */
  justify-content: flex-start;
  // grid-template-columns: 1fr 1fr 1fr;
}
.teamsWrapper {
  width: 100%;
}
.teamDescription {
  display: flex;
  // flex-direction: column-reverse;
  align-items: center;

  .name {
    color: #ffffff;
    font-size: 14px;
    line-height: 1.2;
    text-align: start;
    font-weight: 500;
    flex: 1;
  }

  .img {
    width: 60px;
    height: 60px;
    border-radius: 15px;
    display: block;
    margin: 10px;
  }
}

.result {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 200px;
}
.scoreWrapper {
  display: flex;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin-left: 80px;
  justify-content: flex-start;
}
.score {
  padding: 5px;
}
.time {
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  margin-top: 10px;
}

.scoreDay {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}
.tournamentImage {
  background-image: url(../../../../assets/logo-sm.png);
  position: relative;
  flex: none;
  width: 120px;
  background-size: cover;
  background-position: center;
}
.tournamentImage::after {
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.text {
  display: block;
  padding: 40px 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
}

@media (max-width: 767px) {
  .resultContainer {
    display: flex;
    // grid-template-columns: 1fr;
  }
  .tournamentImage {
    display: none;
  }
  .scoreWrapper {
    margin-left: 0px;
    margin-top: 20px;
    justify-content: center;
  }

  .time {
    margin-top: 20px;
  }
  .teamDescription {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .matchesItemContainer,
  .filterWrapper {
    max-width: 90%;
  }
}
