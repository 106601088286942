.wrapper {
  width: 100%;
  z-index: 99999;
  text-align: center;
  position: relative;
  height: 100px;

  .preloader {
    margin: auto;
    border: 2px solid rgba(255, 255, 255, 0.2);
    width: 32px;
    height: 32px;
    display: inline-block;
    position: absolute;
    top: 45%;
    border-radius: 50%;
    border-right: 2px solid  #ce8926;
    text-align:center;
    animation-name: spin;
    animation-duration: 900ms;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
  }
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  0% { -webkit-transform: rotate(0deg);}
  100% { -webkit-transform: rotate(360deg); }
}

