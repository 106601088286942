ul,
li,
a,
p,
div,
h3,
h4,
img {
  padding: 0;
  margin: 0;
}

.events__about__wrapper {
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px rgba(255, 255, 255, 0.12) solid;
  margin-bottom: 24px;
}
.events__about {
  max-width: 900px;
  padding: 20px;
  margin: 0 auto;
  box-sizing: border-box;
}
.events__about__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 12px;
  margin-top: 24px;
  margin-bottom: 12px;
}
.events__about__description {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.65);
  margin-bottom: 12px;
}
.events__about__data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.about__data__title {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.87);
}
.about__data__description {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.87);
}
.events__about__title--organizer {
  margin-top: 24px;
  margin-bottom: 12px;
}
.events__games__container {
  padding: 5px 5px;
  display: flex;
  gap: 30px;
  /* min-height: 150px; */
  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
}
.events__organizer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  /* background: #212121; */
  /* TODO color wiki*/
  background: rgba(255, 255, 255, 0.05);
  border: 3px solid #212121;
  border-radius: 26px;
}
.events__organizer__team {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  /* background: #212121; */
  /* TODO color wiki*/
  background: rgba(255, 255, 255, 0.05);
  border: 3px solid #212121;
  border-radius: 26px;
  overflow-x: auto;
}

::-webkit-scrollbar-thumb {
  background-color: #818b99;
  border: 3px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}
.events__organizer__team__container {
  display: flex;
}
.organizer__image {
  margin-right: 16px;
}
.organizer__card__title {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 8px;
  margin-right: 10px;
}

@media (min-width: 450px) {
  .organizer__image {
    width: 100px;
    height: 100px;
  }
}
