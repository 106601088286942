:root {
  --bg: #262626;
  --text: #d1d3d4;
  --dark: #000000;
  --white: #ffffff;
  --icon: #808285;
  --apelsin: #f2702c;
  --apelsin-dark: #c44400;
  --nav-bg: #1c1c1c;
  --yellow: #f7a01d;
  --red: #ef5236;
  --blue: #008cff;
  --row-bg: #343435;
}
