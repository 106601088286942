table thead {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.wrapper {
  position: relative;
  max-width: 900px;
  margin: 20px auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 18px;
  width: 100%;
  flex-direction: column;
  padding: 30px 16px;

  .title {
    font-size: 15px;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.87);
  }

  .teams {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    grid-gap: 30px;
    width: 100%;

    > div {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item {
      cursor: pointer;

      .avatarGamer {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .name {
    font-size: 24px;
    flex: 1 1;
    width: 100%;
    text-align: center;
  }

  .values {
    width: 100%;
    display: flex;
    flex-direction: column;

    .item {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      grid-gap: 40px;
      margin: 10px 0;
      padding: 10px;
      border-radius: 16px;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .icon {
        width: 20px;
      }
      .status {
      }

      .nickname {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .bold {
          font-size: 12px;
        }
      }

      .logo {
        max-width: 40px;
        width: 40px;
        height: 40px;
        border-radius: 10%;

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
.checkBox {
  display: flex;
  justify-content: center;
}
.detailWrap {
  margin: 20px auto;
  max-width: 1200px;
  height: 1000px;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 18px;
}
.scrollWrap {
  @media (max-width: 1120px) {
    width: 100%;
    position: relative;
    display: flex;
    display: -webkit-flex;
    -webkit-overflow-scrolling: touch;
  }
}

.tableWrap {
  @media (max-width: 1120px) {
    min-width: 1120px;
  }
  @media (max-width: 768px) {
    min-width: 865px !important;
  }
}

.table {
  color: #ffffff;
  text-transform: uppercase;
  border: 0 none;
  width: 100%;
  border-collapse: collapse;
}
caption {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
}
colgroup {
  display: table-column-group;
}
col {
  display: table-column;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.table tr:last-child {
  border-bottom: 0;
}
.table tr {
  border-bottom: 4px solid rgb(32, 25, 65);
}

.table tr th:first-child {
  text-align: left;
  padding-left: 90px;
}
.table tr th {
  padding-left: 8px;
  padding-right: 8px;
}
.table tr th {
  font-size: 14px;
  color: #909090;
  letter-spacing: 0;
  text-align: center;
  line-height: 16px;
  background-color: rgb(32, 25, 65);
  height: 78px;
}
.table tr th:first-child {
  text-align: left;
  padding-left: 90px;
}
.table tr th:last-child {
  padding-right: 26px;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
// placement
// .table tr:nth-of-type(1) td:first-child {
//   color: #f2a900;
// }
// .table tr:nth-of-type(2) td:first-child {
//   color: #f2a900;
// }
// .table tr:nth-of-type(3) td:first-child {
//   color: #f2a900;
// }

@media (max-width: 1600px) {
  .table tr td:first-child {
    font-size: 16px;
  }
}
.table tr .name {
  text-align: left;
  text-transform: initial;
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    color: #e31a33;
  }
}
.table tr td {
  padding-left: 8px;
  padding-right: 8px;

  font-size: 16px;

  background-color: rgba(255, 255, 255, 0.05);
  height: 70px;
  text-align: center;
}
.table tr td input {
  font-size: 16px;
  caret-color: transparent;
  background-color: rgb(32, 25, 65);
  text-align: center;
}
.table tr td:last-child {
  padding-right: 26px;
}
.table tr .name .logo {
  display: inline-block;
  max-width: 40px;
  max-height: 40px;
  vertical-align: middle;
  margin-right: 10px;
}
.table tr .name .logo img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  display: block;
  border-radius: 10%;
}
.sendResultWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.sendResultButton {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  background-color: #e31a33;
  color: #ffffff;
  font-size: 20px;

  cursor: pointer;
  border: 0 none;
  outline: 0 none;
  transition: all 0.3s ease;
  &:hover {
    background-color: #e31a33;
    opacity: 0.8;
    width: 210px;
    height: 55px;
  }
}
