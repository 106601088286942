.games {
  padding: 0 24px;
  margin: 13px auto;
}
.games__title-icon {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.games__title {
  position: relative;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;

  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #f6a020;
  padding: 8px 20px;
  margin-bottom: 32px;
}
.games__go-back-link {
  position: absolute;
  top: 0;
  left: 20px;
  display: block;
  width: 44px;
  height: 44px;
  background: #2c2c2c;
  border-radius: 14px;
  border: 1px white solid;
  cursor: pointer;
}
.games__go-back-link:before,
.games__go-back-link:after {
  content: "";
  position: absolute;
  top: 25px;
  left: 15px;
  width: 12px;
  height: 2px;
  background: white;
  transform: rotate(60deg);
}
.games__go-back-link:after {
  transform: rotate(-60deg);
  top: 16px;
  left: 15px;
}
.games__list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.games__item-list {
  width: 130px;
  height: 130px;
  margin: 20px 20px 30px 20px;
  cursor: pointer;
  border: 1px transparent solid;
  text-align: center;
}
.group-name {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: #f6a020;
  text-transform: uppercase;
  margin-top: 8px;
}
.games__list__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 20px;
}

.games__list__group {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;

  line-height: 28px;
  text-transform: uppercase;
  color: #f6a020;
  padding: 8px 20px;
  margin: 10px;
}
.games__input-name {
  width: 120px;
  color: black;
  border-radius: 10px;
  text-decoration: none;
  padding-left: 5px;
}
.games__input-name:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}
.games__input-name::placeholder {
  font-weight: bold;
  font-size: 12px;
}
.games__item-list--active {
  opacity: 0.6;
}
.games__item-list-image {
  border-radius: 17px;
  background: #6d6591;
}
.games__item-list-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 8px;
}
@media (max-width: 450px) {
  .games__title {
    padding-left: 45px;
  }
  .games__list {
    justify-content: space-around;
  }
}
.submit-button-wrapper {
  width: 100%;
}
.submit-button {
  display: block;
  padding: 16px 40px;
  width: 342px;
  background: #f6a020;
  border-radius: 18px;
  margin: 40px auto;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #1e1e1e;
  cursor: pointer;
}
.submit-button--disabled {
  background: rgba(255, 255, 255, 0.4);
}

@media (max-width: 450px) {
  .submit-button {
    width: 270px;
  }
}
