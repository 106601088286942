.styledCard {
  // width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
}

.styledCard__header {
  width: 112px;
  height: 112px;
  border: 1px solid #818184;
  border-radius: 32px;
  //  background-color: #414042;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.styledCard__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  width: 110px;
  height: 48px;
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    white-space: normal;
    overflow-wrap: anywhere;
  }
}

.styledCard__image {
  width: 112px;
  height: 112px;
  border-radius: 32px;
  object-fit: cover;
}
