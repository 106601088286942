.playersContainer {
  margin-top: 20px;
  display: flex;
  width: 80%;

  .playerCard {
    margin-top: 13px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:first-child {
      margin-top: 0;
    }
    .leftContainer {
      display: flex;
      .imgContainer {
        width: 35px;
        height: 35px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
      .textContainer {
        margin-left: 5px;
        .teamName {
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
        }
        .subtitle {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
    .btn {
      position: relative;
      width: 44px;
      height: 44px;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        top: calc(50% - 6px);
        left: calc(50% - 6px);
        width: 14px;
        height: 14px;
      }
    }
    .plus {
      &::after {
        background: url("../../assets/icons/cross.svg");
      }
    }
    .checkMark {
      &::after {
        background: url("../../assets/icons/checkMark.svg");
      }
    }
    .plusMini {
      margin-right: 10px;
      font-size: 40px;
      // transform: rotate(45deg);
      cursor: pointer;
    }
  }
}
