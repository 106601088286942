.modal__wrapper {
  background: #181818;
  padding: 15px 15px 60px 15px;
  box-sizing: border-box;
}
.modal {
  width: 500px;
  padding: 20px 12px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 20px;
  border: 2px #707070 solid;
}

.modal__image {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 100px;
}
.modal__header__about__title {
  font-weight: bold;
  font-size: 20px;
}
.modal__info {
  padding: 10px;
  margin: 10px 0;
}
.modal__title {
  display: block;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.modal__title__header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.modal__title__buttons {
  display: flex;
  flex-direction: column;

  button {
    font-size: 20px !important;
    height: 50px !important;
    width: 50% !important;
  }

  .send_result {
    padding: 10px;
    width: 100%;

    :hover {
      color: rgb(90, 160, 33);
    }
  }
}

.close__button {
  p {
    padding-top: 20px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  p:hover {
    color: orange;
  }
}
.support__button {
  text-align: end;
  a {
    color: #f7a01d;
    cursor: pointer;
    font-weight: 500;
  }
}
@media (max-width: 768px) {
  .modal {
    width: 300px;
  }
  .go__to__buttons {
    flex-direction: column;
  }
}

@media (max-width: 350px) {
  .modal {
    width: 240px;
  }
  .modal__title__header {
    font-size: 16px;
  }
}
