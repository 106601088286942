.match {
  .hierarchy {
    max-width: 900px;
    padding: 30px 0 20px;
    margin: 0 auto;
    font-size: 12px;
  }

  .alignCenter {
    align-items: center;
  }

  .info {
    max-width: 900px;
    padding: 20px 0;
    margin: 0 auto;
    font-size: 14px;
    display: flex;
  }

  .description {
    line-height: 1.2;
    display: flex;
    align-items: center;
    font-size: 16px;

    a {
      text-decoration: none;
      cursor: pointer;
    }
  }

  .type {
    color: #f6a020;
    text-transform: capitalize;
    margin-left: 0.5rem;
  }

  .span {
    margin-left: 6px;
  }

  .pro {
    padding: 0.2rem 0.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.2rem;
    font-size: 0.6rem;
    margin-right: 5px;
    line-height: 1;
    font-weight: 600;
  }

  .item {
    display: inline;
    .link {
      cursor: pointer;
      transition: all 0.3s;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &:after {
      content: " | ";
    }

    &:last-child {
      &:after {
        content: "";
      }
    }
  }

  .teamsName {
    span {
      margin: 0;
      font-size: 26px;
      font-weight: 400;
      line-height: 1.6;
    }
  }

  .logo {
    width: 50px;
    height: 50px;
    border-radius: 30px;
    margin-right: 15px;
  }

  .scoreboard {
    max-width: 900px;
    padding: 20px;
    margin: 20px auto;
    box-sizing: border-box;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 2fr 1fr;
    border-radius: 26px;
  }

  .scoreboardTeam {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
  }

  .teamImageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.87);
    margin-bottom: 20px;

    .img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
  }

  .teamTitle,
  .teamTag {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.87);
  }

  .score {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90px;
    font-weight: 600;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.87);
  }

  .scoreParameters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;

    p {
      margin-bottom: 6px;
    }
  }

  .defeat {
    margin-top: 20px;
    font-size: 14px;
  }

  .loser {
  }

  .timeMatch {
  }

  .bestOf {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.87);
  }

  .status {
    padding: 4px 8px;
    max-width: 60px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    margin: 10px;
  }

  .scoreWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
