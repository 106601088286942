.notification {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  background: rgba(255, 255, 255, 0.05);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: #ffffff;
  flex-wrap: wrap;
  overflow: auto;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
  }
  .Link {
    color: #ffffff;
    margin-left: 17px;
    display: flex;
  }
  .LinkViewed {
    color: #ffffff;
    margin-left: 17px;
    opacity: 0.5;
    display: flex;
  }
  .icon-box {
    margin: 10px;
    border-radius: 12px;
    position: relative;
  }
}
.notificationUnread .notification::before {
  border: 10px solid transparent;
  border-left: 10px solid #f7a01d;
  border-top: 10px solid #f7a01d;
  border-radius: 5px;
}

.notificationFooter {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid rgba(255, 255, 255, 0.274);
  background: rgba(255, 255, 255, 0.05);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 10px 0;
}

.noNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #ffffff;
  font-size: 20px;
}
