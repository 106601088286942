.modal__wrapper {
  background: #181818;
  padding: 15px 15px 60px 15px;
  box-sizing: border-box;
}
.modal {
  width: 500px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 20px;
  border: 2px #707070 solid;
}
.modal__header__about {
  height: 70px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal__header__about__title {
  padding-left: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #ffffff;
}

.modal__title {
  display: block;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.modal__info {
  display: flex;
  flex-direction: column;
}
.modal__info__value {
  display: flex;
  .modal__info__value__first {
    width: 125px;
    margin-left: 15px;
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    text-align: start;
  }
  .modal__info__value__second {
    width: 250px;
    margin-top: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    line-height: 30px;
    text-align: start;
  }
}

.modal__title__header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.modal__title__buttons {
  display: flex;
  flex-direction: column;
  p {
    padding-top: 20px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  p:hover {
    color: orange;
  }
}

@media (max-width: 768px) {
  .modal {
    width: 400px;
  }
}

@media (max-width: 425px) {
  .modal {
    width: 300px;
  }
}
