.playerContainer {
  position: relative;
  margin: 20px 0 0 10px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  width: 290px;
  // border: 1px solid;
  height: 50px;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
  cursor: pointer;
  .indicator {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    bottom: 0;
    width: 30px;
    line-height: 48px;
    background-color: #000000;
    z-index: 1;
    cursor: pointer;
  }
  .contextMenuContainer {
    position: absolute;
    top: -20px;
    left: calc(50% - 50px);
    display: flex;
    justify-content: space-between;
    width: 100px;
    .imgContainer {
      width: 20px;
      height: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .userContainer {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    width: 290px;
    // border: 1px solid;
    height: 50px;
    margin-bottom: 12px;
    margin-right: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: rgba(29, 31, 36, 0.8);
    border: 1px solid transparent;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    @media screen and (max-width: 500px) {
      width: 100%;
    }
    .imgContainer {
      // border-radius: 50%;
      // overflow: hidden;
      // width: 100px;
      // height: 100px;
      float: left;
      position: relative;
      border-radius: 0;
      margin: 0px 20px 6px 0px;
      padding: 0;
      img {
        // width: 100%;
        // height: 100%;
        width: 48px;
        height: 48px;
        border-radius: 2px;
      }
    }
    .contentBlock {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-transform: capitalize;
      margin: 12px 22px 12px 12px;
      font-size: 14px;
      line-height: 15px;
      padding-right: 18px;
      .titleRole {
        font-weight: normal;
        font-size: 11px;
      }
    }
  }
}
.playerContainer:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}
