.registrationContainer {
  margin: 35px 0;
  display: flex;
  justify-content: center;
  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    .title {
      width: 100%;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
    }
    .teamInfo {
      margin-top: 30px;
      padding: 15px;
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border-radius: 12px;
      .teamTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
      }
      .buttomContainerInfo {
        margin-top: 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .name,
        .vs {
          width: 143px;
          text-align: center;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
    .addContainer {
      margin-top: 30px;
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .rosterContainer {
        .rosterTitle {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
        }
      }
      .addPlayer {
        position: relative;
        width: 125px;
        height: 34px;
        line-height: 34px;
        border: none;
        background: #343334;
        box-shadow: 0px 10px 10px rgba(33, 33, 33, 0.35);
        border-radius: 8px;
        padding-left: 28px;
        cursor: pointer;
        &::after {
          content: "";
          position: absolute;
          top: 10px;
          left: 12px;
          width: 14px;
          height: 14px;
          background: url("../../assets/icons/cross.svg");
        }
      }
    }
    .playerRow {
      margin-top: 15px;
      width: 90%;
      padding: 5px;

      border-radius: 10px;
      .rowTitle {
        margin-left: 40px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
      }
    }
    .playersContainer {
      margin-top: 11.5px;
      display: flex;
      width: 90%;
      .playerCard {
        margin-top: 13px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid red;

        &:first-child {
          margin-top: 0;
        }
        .leftContainer {
          display: flex;
          .imgContainer {
            width: 35px;
            height: 35px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .textContainer {
            margin-left: 5px;
            .teamName {
              font-weight: 700;
              font-size: 14px;
              line-height: 19px;
            }
            .subtitle {
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
            }
          }
        }
        .plus {
          margin-right: 10px;
          font-size: 40px;
          transform: rotate(45deg);
        }
      }
    }
    .addPlayer {
      position: relative;
      width: 125px;
      height: 34px;
      line-height: 34px;
      border: none;
      background: #343334;
      box-shadow: 0px 10px 10px rgba(33, 33, 33, 0.35);
      border-radius: 8px;
      padding-left: 28px;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        top: 10px;
        left: 12px;
        width: 14px;
        height: 14px;
        background: url("../../assets/icons/cross.svg");
      }
    }
  }
  .playerRow {
    margin-top: 15px;
    width: 90%;
    padding: 5px;
    .rowTitle {
      margin-left: 40px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
    }
  }
  .btnDone {
    margin-top: 30px;
    width: 345px;
    height: 44px;

    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 44px;
    letter-spacing: 0.04em;
    color: #ffffff;
    text-align: center;
    border: none;
    cursor: pointer;
  }
}
