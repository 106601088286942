.matches {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select {
  margin-right: 20px;
  min-width: 240px;
}

.transparentButton {
  background: none;
  border: 0;
  width: 45px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.datePickerWrapper {
  margin-right: 20px;

  input {
    min-height: 40px;
  }
}

.title {
  font-size: 22px;
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.filterWrapper {
  padding: 20px 20px;
  margin: -9px 1px 20px;
  display: flex;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .matchesItemContainer,
  .filterWrapper {
    max-width: 100%;
    flex-direction: column;
    // padding: 0 30px;
  }
  .datePickerWrapper {
    max-width: 240px;
  }
}
