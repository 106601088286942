.pagination {
  button {
    color: white;
  }
}

.calendarList {
  padding: 20px 0;
  box-sizing: border-box;
  height: 100%;
}

.paginatorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}


.filters {
  max-width: 1170px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

.clearButton {
  margin-bottom: 15px;
  background: none;
  padding: 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;

  .label {
    font-weight: 300;
  }
}
