.wrapper {
  padding: 30px 16px;
  border-radius: 18px;
  margin-bottom: 20px;

  .form {
    width: 100%;
  }

  .selectWrapper {
    display: flex;
    justify-content: flex-end;

    &:last-child {
      justify-content: flex-start;
    }
  }

  .score {
    display: grid;
    grid-template-columns: 1fr 40px 1fr;
    align-items: center;
    grid-gap: 10px;
  }
  .wrapperCheckBox {
    display: grid;
    grid-template-columns: 1fr 70px 1fr;
    align-items: center;
    grid-gap: 10px;
  }
  .checkBox {
    display: flex;
    justify-content: flex-end;
    &:last-child {
      justify-content: flex-start;
    }
  }
  .checkBoxTextWrapper {
    position: relative;
    display: inline-block;
    padding: 10px 0;
    cursor: help;
  }
  .checkBoxTextWrapper .checkBoxText {
    visibility: hidden;
    width: 300px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    border: 1px solid;
    padding: 10px 0px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    margin-left: -60px;
    left: -75%;
  }
  .checkBoxTextWrapper .checkBoxText::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(255, 255, 255) transparent transparent transparent;
  }

  .checkBoxTextWrapper:hover .checkBoxText {
    visibility: visible;
  }

  .checkBoxTextWrapper:hover .checkBoxText {
    visibility: visible;
  }
  .wrapperTeam {
    position: absolute;
    top: 56px;
    left: 0;
    padding: 0 30px;
    width: 80%;
    box-sizing: border-box;
    z-index: 7;
    margin-left: 90px;
  }

  .separator {
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  .title {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .error {
    color: red;
    text-align: center;
    width: 100%;
    margin: 10px 0;
  }

  .button {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    padding: 10px;
    border: 0;
    border-radius: 10px;
    transition: all 0.3s;
    margin-top: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
