.playersContainer {
  margin-top: 11.5px;
  display: flex;
  width: 90%;

  .playerCard {
    margin-top: 13px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:first-child {
      margin-top: 0;
    }
    .leftContainer {
      display: flex;
      .imgContainer {
        width: 35px;
        height: 35px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          border-radius: 20%;
        }
      }
      .textContainer {
        margin-left: 5px;
        .teamName {
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
        }
        .subtitle {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }

    .btn {
      position: relative;
      width: 44px;
      height: 44px;
      box-shadow: 0px 10px 10px rgba(32, 25, 66, 0.35);
      border-radius: 8px;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        top: calc(50% - 6px);
        left: calc(50% - 6px);
        width: 14px;
        height: 14px;
      }
    }
    .button-team-list__title-arrow {
      filter: invert(92%) sepia(55%) saturate(1%) hue-rotate(152deg)
        brightness(104%) contrast(77%);
      position: absolute;
      transform: rotate(-90deg);
      left: 105%;
      top: 0;
      width: 15px;
      height: 15px;
      transition: all 0.1s ease-in-out;
    }
    .plus {
      &::after {
        background: url("../../assets/icons/downarrow.svg");
        color: white;
        filter: invert(92%) sepia(55%) saturate(1%) hue-rotate(152deg)
          brightness(104%) contrast(77%);
        position: absolute;
        transform: rotate(-90deg);
        width: 15px;
        height: 15px;
        transition: all 0.1s ease-in-out;
      }
    }
    .checkMark {
      &::after {
        background: url("../../assets/icons/downarrow.svg");
        transform: rotate(0deg);
        color: white;
        filter: invert(92%) sepia(55%) saturate(1%) hue-rotate(152deg)
          brightness(104%) contrast(77%);
        position: absolute;
        width: 15px;
        height: 15px;
        transition: all 0.1s ease-in-out;
      }
    }
    .plusMini {
      margin-right: 10px;
      font-size: 40px;
      transform: rotate(45deg);
      cursor: pointer;
    }
  }
}
.playerRow {
  margin-top: 15px;
  width: 90%;
  padding: 5px;

  border-radius: 10px;

  .rowTitle {
    margin-left: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
  }
}
