.styledRow {
  // background-color: #3f317c;
  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
}

.styledRow__header {
  padding: 10px 23px;
  z-index: 2;
  // background-color: var(--bg);
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
  p {
    font-size: 17px;
    font-style: normal;
    letter-spacing: -0.67px;
    line-height: normal;
    text-decoration: underline;
    padding-left: 25px;
    cursor: pointer;
  }
}
.styledRow__moreInfo {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  letter-spacing: 0.03em;
  text-decoration-line: underline;
  text-transform: capitalize;

  color: #ffa900;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.styledRow__footer {
  padding: 18px 31px 18px;
  display: flex;
  gap: 50px;
  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
  min-height: 150px;
  background: rgba(255, 255, 255, 0.05);
  margin-top: 10px;
}
.styledRow::-webkit-scrollbar {
  height: 10px;
}
.styledRow::-webkit-scrollbar-thumb {
  background-color: #818b99;
  border: 3px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}
