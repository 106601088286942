.modal__wrapper {
  background: #181818;
  padding: 15px 15px 60px 15px;
  box-sizing: border-box;
}
.modal {
  width: 500px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 20px;
  border: 2px #707070 solid;
}

.modal__title {
  display: block;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 25px;
}
.modal__info {
  display: flex;
  flex-direction: column;
}
.modal__header__about {
  height: 70px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal__header__about__title {
  padding-left: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #ffffff;
}
.modal__info__header__type {
  text-align: start;
  padding: 20px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.modal__info__header__players__container {
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal__info__header {
  width: 112px;
  height: 112px;
  border: 1px solid #818184;
  border-radius: 32px;
  //  background-color: #414042;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.modal__info__name {
  p {
    padding-top: 10px;
    text-align: start;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.modal__info__footer__type {
  text-align: start;
  padding: 20px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.modal__info__footer__players {
  display: flex;
  flex-wrap: wrap;
}
.modal__info__footer__players__container {
  padding: 15px 10px;
}
.footer__players__container__avatar {
  position: relative;
  p {
    position: absolute;
    bottom: 8px;
    left: 0px;
    background: blueviolet;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    background: #1f193fde;
    border-radius: 0px 0px 32px 32px;
  }
}

.footer__players__container__name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.modal__info__logo {
  width: 112px;
  height: 112px;
  border-radius: 32px;
}

.modal__title__buttons {
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
}
.buttons__cancel {
  display: flex;
  align-content: center;
  height: 50px;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 10px;
  p {
    padding: 10px;

    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
  }
}
.buttons__goTo {
  display: flex;
  align-content: center;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #ffa900;

  p {
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .modal {
    width: 400px;
  }
}

@media (max-width: 425px) {
  .modal {
    width: 300px;
  }
}
