.container {
  border-radius: 10px;
  overflow-x: auto;
  padding: 12px 24px 15px;

  &::-webkit-scrollbar {
    height: 0;
  }
}

.list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 36px;
  min-width: 550px;
}

.listItem {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: -0.55px;
  line-height: normal;
  padding-bottom: 5px;
  border-bottom: 1px solid transparent;
  transform: translateY(3px);
  cursor: pointer;

  &:last-of-type {
    margin-right: 24px;
  }
}

.active {
  border-color: var(--yellow);
}
