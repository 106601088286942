.auth {
  display: flex;
  justify-content: center;
  background-size: cover;
  min-height: 100vh;
  padding: 85px 28px;
}
.language__switch {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
}
.auth__text {
  text-align: center;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

.auth__form {
  padding: 16px 20px;
  border-radius: 10px;
}

.sign__up {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  // color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 16px;
  span {
    // color: #eda211;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
    margin-left: 16px;
  }
}

.input__group {
  // border-radius: 16px;
  // overflow: hidden;
  margin: 25px 0;
}
.login__input,
.password__input {
  display: block;
  position: relative;
  width: 100%;
  height: 46px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 30px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.login__input::-webkit-input-placeholder,
.password__input::-webkit-input-placeholder {
  font-weight: 300;
  color: var(--main-text--active);
}

.login__input::-moz-placeholder,
.password__input::-moz-placeholder {
  font-weight: 300;
  color: var(--main-text--active);
}

.login__input:-ms-input-placeholder,
.password__input:-ms-input-placeholder {
  font-weight: 300;
  color: var(--main-text--active);
}

.login__input::-ms-input-placeholder,
.password__input::-ms-input-placeholder {
  font-weight: 300;
  color: var(--main-text--active);
}

.login__input::placeholder,
.password__input::placeholder {
  font-weight: 300;
  color: var(--main-text--active);
}

.login__input:hover::-webkit-input-placeholder,
.password__input:hover::-webkit-input-placeholder {
  color: var(--main-text--active);
}

.login__input:hover::-moz-placeholder,
.password__input:hover::-moz-placeholder {
  color: var(--main-text--active);
}

.login__input:hover:-ms-input-placeholder,
.password__input:hover:-ms-input-placeholder {
  color: var(--main-text--active);
}

.login__input:hover::-ms-input-placeholder,
.password__input:hover::-ms-input-placeholder {
  color: var(--main-text--active);
}

.login__input:hover::placeholder,
.password__input:hover::placeholder {
  color: var(--main-text--active);
}
.btn__group {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.newSiteRegister {
  padding: 10px;
  background: white;
  color: black;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  a {
    color: orange;
    font-weight: bold;
  }
}
.forgot__password {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  margin: 20px 0;
  cursor: pointer;
  div {
    display: flex;
    align-items: center;
  }
  a {
    text-decoration: none;
  }
}
.support__button {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: -0.56px;
  line-height: normal;
  text-align: start;
  margin-top: 10px;
  cursor: pointer;
  a {
    color: #f7a01d;
    text-decoration: none;
  }
}

// social auth

.social__auth {
  margin-top: 25px;
}

.social__label {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: -0.61px;
  line-height: normal;
}

.social__wrapper {
  margin-left: 30px;
  display: flex;
  align-items: center;

  .google,
  .facebook {
    padding: 13px 25px 10px;
    border-radius: 20px;
    background-color: #f26052;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
      width: 23px;
      height: 23px;
    }
  }

  .google {
    padding: 14px 15px 10px;
  }

  .facebook {
    background-color: #3669a5;
    margin-left: 10px;
  }
}
.footer__powerBy {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  font-weight: 400;
  line-height: 30px;
}
.modal__wrapper {
  background: #181818;
  padding: 15px 15px 60px 15px;
  box-sizing: border-box;
}
.modal {
  width: 500px;
  padding: 20px 12px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 20px;
  border: 2px #707070 solid;
}

.modal__image {
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
  width: 100px;
}

.modal__title {
  display: block;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.modal__title__header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.modal__title__footer {
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
}
.modal__title__footer__newAccount {
  color: orange;
  cursor: pointer;
}

.modal__title__link {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  a {
    color: orange;
  }
}
.modal__button {
  display: block;
  text-align: center;
  margin-top: 15px;
}
@media (max-width: 768px) {
  .modal {
    width: 300px;
  }
}
@media (max-width: 400px) {
  .auth {
    padding: 50px 1rem;
  }
  .auth__form {
    .btn__group {
      flex-direction: column;
      gap: 10px;

      button {
        width: 100%;
      }
    }
  }
  .forgot__password {
    font-size: 16px;

    .forgot__password__checkbox {
      font-size: 16px;
    }
  }
  .sign__up {
    font-size: 16px;
    span {
      font-size: 16px;
    }
  }
  .social__auth {
    .social__wrapper {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

@media (max-width: 350px) {
  .modal {
    width: 240px;
  }
  .modal__title__header {
    font-size: 16px;
  }

  .auth__form {
    .btn__group {
      flex-direction: column;
      gap: 10px;

      button {
        width: 100%;
      }
    }
  }

  .social__auth {
    .social__wrapper {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
