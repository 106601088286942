.paginatorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;


}

.pagination {
  button {
    color: white;
  }
}

.wrapperSelect {
  margin: 0 20px;
}

.clearButton {
  margin-bottom: 15px;
  background: none;
  padding: 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .label {
    font-weight: 300;
  }
}

.calendarList {
  padding: 20px 0;
  box-sizing: border-box;
  height: 100%;
}

.filters {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1170px;
  width: 100%;
  margin: 20px auto;
}

@media (max-width: 767px) {
  .filters {
    flex-wrap: wrap;
  }
}

@media (max-width: 560px) {
  .filters {
    flex-direction: column;
  }
}
