.notification__result-item-viewed .notification__result-item {
  background: rgba(37, 101, 210, 0.203);
}
ul,
li,
a,
p,
div,
h3,
h4,
img {
  padding: 0;
  margin: 0;
}
.notification {
  display: flex;
  flex-direction: column;
  color: #ffffff;
}
.notification__search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 18%;
  /* background-color: rgb(38, 34, 35); */
  background: rgb(32, 25, 65);
  border-bottom: 1px #9a9ca6 solid;
}
.search__searching-input {
  flex-grow: 33;
  padding: 7px 31px;
  margin: 0 50px;
  font-size: 22px;
  line-height: 22px;
  border: 1px #ef5236 solid;
  border-radius: 20px;
  background: #939598;
  color: white;
}
.notification__search-link--bell {
  position: relative;
}
.notification__search-link--bell:before {
  content: "";
  position: absolute;
  top: 2px;
  right: 1px;
  width: 10px;
  height: 10px;
  background: #ef5236;
  border-radius: 50%;
}
.search__searching-glass {
  position: absolute;
  top: 8px;
  left: -80px;
}
.notification__result {
  height: 100%;
  padding: 15px 18%;
  padding-bottom: 60px;
}
.notification__result-title {
  margin: 11px 0;
  margin-top: 20px;
  font-size: 26px;
  line-height: 26px;
  color: #ffffff;
}
.notification__accept-button {
  background: lime;
  color: #000;
  margin-left: 10px;
  width: 100px;
  height: 30px;
  border-radius: 12px;
  cursor: pointer;
}
.notification__reject-button {
  background: red;
  color: #000;
  margin-left: 10px;
  width: 100px;
  height: 30px;
  border-radius: 12px;
  cursor: pointer;
}
.notification__icon-box {
  margin: 10px;
  border-radius: 12px;
  position: relative;
}

.notification__result-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  margin-bottom: 7px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgb(28, 28, 28);
  border-radius: 10px;
  font-style: italic;
  color: #ffffff;
  flex-wrap: wrap;
  overflow: auto;
}

.notification__result-item:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
}
.notification__result-list--yellow .notification__result-item:before {
  border: 10px solid transparent;
  border-left: 10px solid #f7a01d;
  border-top: 10px solid #f7a01d;
  border-radius: 5px;
}
.notification__result-list--black .notification__result-item:before {
  border: 10px solid transparent;
  border-left: 10px solid #000000;
  border-radius: 5px;
  border-top: 10px solid #000000;
}
.notification__result-list--white .notification__result-item:before {
  border: 10px solid transparent;
  border-left: 10px solid #ffffff;

  border-radius: 5px;
  border-top: 10px solid #ffffff;
}
.notification__result-list--blue .notification__result-item:before {
  border: 10px solid transparent;
  border-left: 10px solid #00aeef;
  border-top: 10px solid #00aeef;
  border-radius: 5px;
}
.notification__result-list--red .notification__result-item:before {
  border: 10px solid transparent;
  border-left: 10px solid #ef5236;
  border-top: 10px solid #ef5236;
  border-radius: 5px;
}
.notification__result-list--green .notification__result-item:before {
  border: 10px solid transparent;
  border-left: 10px solid #00a651;

  border-radius: 5px;
  border-top: 10px solid #00a651;
}
.notification__result-item-link {
  color: #ffffff;
  margin-left: 17px;
  display: flex;
}
.notification__result-item-link-viewed {
  color: #ffffff;
  margin-left: 17px;
  opacity: 0.5;
  display: flex;
}
.result-news-events-tournaments__shadow {
  background: url("../../assets//images/shadow.svg") no-repeat;
  background-size: 100%, auto;
  width: 100%;
  height: 150px;
  margin-top: -25px;
  margin-bottom: -55px;
  box-sizing: border-box;
}
.notification__result-item-nickname {
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
}
.notification__result-item-message {
  padding: 0px 10px 0px 10px;
}
.notification__pagination {
  height: 100px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

@media (max-width: 500px) {
  .notification__search {
    padding: 19px 5%;
  }
  .search__searching-glass {
    left: -40px;
  }
  .search__searching-input {
    width: 200px;
    margin: 0 10px;
    padding: 7px 21px;
    box-sizing: border-box;
  }
  .notification__result {
    padding: 15px 5%;
  }
}
@media (max-width: 1700px) {
  .result-news-events-tournaments__shadow {
    margin-top: -22px;
    height: 120px;
  }
}
@media (max-width: 1300px) {
  .result-news-events-tournaments__shadow {
    margin-top: -18px;
    height: 110px;
  }
}
@media (max-width: 1000px) {
  .result-news-events-tournaments__shadow {
    margin-top: -15px;
    height: 80px;
  }
}
@media (max-width: 800px) {
  .result-news-events-tournaments__shadow {
    margin-top: -11px;
    height: 60px;
  }
}
@media (max-width: 500px) {
  .result-news-events-tournaments__shadow {
    height: 50px;
  }
}

@media (max-width: 500px) {
  .notification__search {
    padding: 19px 5%;
  }
  .search__searching-glass {
    left: -40px;
  }
  .search__searching-input {
    width: 200px;
    margin: 0 10px;
    padding: 7px 21px;
    box-sizing: border-box;
  }
  .notification__result {
    padding: 15px 5%;
  }
}
