.discipline {
  padding: 0 24px;
  margin: 13px auto;
}
.discipline__title {
  position: relative;
  /* font-family: "Rubik"; */
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #f6a020;
  padding: 8px 20px;
  margin-bottom: 32px;
}
.discipline__go-back-link {
  position: absolute;
  top: 0;
  left: 20px;
  display: block;
  width: 44px;
  height: 44px;
  background: #2c2c2c;
  border-radius: 14px;
  border: 1px white solid;
  cursor: pointer;
}
.discipline__go-back-link:before,
.discipline__go-back-link:after {
  content: "";
  position: absolute;
  top: 25px;
  left: 15px;
  width: 12px;
  height: 2px;
  background: white;
  transform: rotate(60deg);
}
.discipline__go-back-link:after {
  transform: rotate(-60deg);
  top: 16px;
  left: 15px;
}
.discipline__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.discipline__item-list {
  margin: 21px 20px;
  cursor: pointer;
  border: 1px transparent solid;
  text-align: center;
}
.discipline__item-list--active {
  opacity: 0.5;
  border: 1px dotted #f6a020;
}
.discipline__item-list-image {
  border-radius: 17px;
}
.discipline__item-list-text {
  /* font-family: "Rubik"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 8px;
}
@media (max-width: 450px) {
  .discipline__title {
    padding-left: 45px;
  }
  .discipline__list {
    justify-content: space-around;
  }
}
.submit-button-wrapper {
  width: 100%;
}
.submit-button {
  display: block;
  padding: 16px 40px;
  width: 342px;
  background: #f6a020;
  border-radius: 18px;
  margin: 40px auto;
  /* font-family: "Rubik"; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #1e1e1e;
  cursor: pointer;
}
.submit-button--disabled {
  background: rgba(255, 255, 255, 0.4);
}

@media (max-width: 450px) {
  .submit-button {
    width: 270px;
  }
}
