.roundDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  width: 100%;
  flex-direction: column;
  padding: 30px 16px;

  .title {
    font-weight: 500;
    font-size: 15px;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.87);
  }

  .subtitle {
    font-weight: 500;
    font-size: 10px;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.87);
  }

  .nameTeam {
    font-size: 24px;
    flex: 1;
    width: 100%;
    text-align: center;
  }

  .score {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 80px;
    justify-content: space-between;
    font-size: 28px;
  }

  .rounds {
    padding-top: 10px;
    display: flex;
    width: 80px;
    justify-content: space-between;
    font-size: 28px;
  }

  .scoreWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    width: 100%;
  }
}
