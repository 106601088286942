.burgerMenu__wrapper {
  background-color: var(--main-bg-color);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 9999;
  width: 90%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0;
}

.burgerMenu__inner {
  margin: 0;
}

.burgerMenu__wrapper-hide {
  display: none;
}

.burgerMenu__wrapper-show {
  display: flex;
}

.burgerMenu__item, .burgerMenu__item-head {
  list-style: none;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  border-left: 5px solid var(--main-bg-color);
}

.burgerMenu__item-head {
  padding: 20px;
  box-sizing: border-box;
}

.burgerMenu__item-content {
  text-decoration: none;
  color: var(--main-text--active);
}

.burgerMenu__item:hover {
  background-color: #171717;
  cursor: pointer;
  border-left: 5px solid var(--main-title-text);
  transition: .7s ease;
}

.burgerMenu__close {
  width: 50px;
  height: 50px;
  background: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  margin: auto;
  margin-right: 0;
  margin-top: 0;
}

.burgerMenu__close:hover {
  cursor: pointer;
  color: var(--main-text--active);
  border: 1px solid var(--main-text--active);
  transition: .7s ease;
}