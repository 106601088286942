.passport {
  :root {
    --main-bg-color: #000000;
    --main-text-color: rgb(154, 156, 166);
    --main-title-text: #f2702c;
    --main-text--active: #9a9ca6;
    --main-non-active: #6c6d74;
    --main-active: #9a9ca6;
    --full-height-without-menu: calc(100vh - 80px);
    --menu-height: 20px;
    --footer-back: #1c1c1c;
    position: relative;
  }
  .transparentBg {
    background: rgba(255, 255, 255, 0.05);
  }

  .logoAuth {
    content: url("./assets/images/passport-logo.png");
    max-width: 320px;
  }
  .headerLogoImageSm {
    content: url("./assets/logo-sm.png");
  }
  .headerLogoImageLg {
    content: url("./assets/images/passport-logo.png");
  }
  .bgGradient {
    background: linear-gradient(
      90deg,
      rgba(46, 25, 65, 1) 0%,
      rgba(53, 49, 124, 1) 52%
    );
  }
  .whiteText {
    color: #ffffff;
  }
  .mainColorfulText {
    color: #eda211;
  }

  .whiteBorder {
    border: 1px solid #ffffff;
  }
  .contentBoxColor {
    background: rgb(32, 25, 65);
  }
  .userAboutColor {
    color: #d5cbff;
  }
  .headerBg {
    background: rgb(32, 25, 65);
  }
  .headerDropMainColor {
    background: #3f317c;
    li {
      background: #2b244a;
    }
    li.active {
      a {
        color: #eda211;
      }
    }
    a {
      color: #fff;

      &:hover,
      &:focus {
        color: #eda211;
      }
    }
  }
  .headerNavLinksColor {
    li.active {
      a {
        color: #eda211;
      }
    }

    a {
      color: #fff;

      &:hover,
      &:focus {
        color: #eda211;
      }
    }
  }
  .footerBg {
    background: rgb(32, 25, 65);
  }
  .settingsMainTitleBg {
    background: #1f193f;
  }
  .settingsTitleBg {
    // background: #1f193f;
  }
  .reactSelectClors {
    border: 1px solid #8881ab;
    span {
      background: #1f1941;
    }
    .react-dropdown-select-dropdown {
      background: #1f1941;
      border-color: rgba(213, 203, 255, 0.6);
    }
    .react-dropdown-select-option {
      background: #1f1941;

      &-label {
        background: #1f1941;

        font-size: 12px;
        white-space: nowrap;
      }

      &-remove {
        background: #1f1941;
      }
    }
    &:hover,
    &:focus,
    &:focus-within {
      border-color: rgba(213, 203, 255, 0.6);
    }
  }
  .reactSelectAuthColors {
    .react-dropdown-select-item {
      &:hover {
        background: #47449deb;
      }

      &.react-dropdown-select-item-selected {
        background: #47449d99;
      }
    }
  }
  .styledInputColor {
    border: 1px solid #8881ab;
  }
  .calendarItemBorder {
    border: 1px solid #756d9e;
  }
  .matchItemBg {
    background: rgb(43, 36, 74);
    box-shadow: 0 12px 13px 0px #00000075;
    &:hover {
      box-shadow: 0 12px 13px 0px rgba(0, 0, 0, 0.7);
    }
  }
  .matchInfoScoreboardBg {
    background: rgb(32, 25, 65);
  }
  .matchInfoStatusBg {
    background: #f6a020;
    color: #000000;
    font-weight: 600;
  }
  .matchInfoLoserColor {
    color: #f6a020;
  }
  .matchInfoProBg {
    background: #f6a020;
  }
  .matchMembersWrapperBg {
    background-color: rgba(43, 36, 74, 0.5);
  }
  .matchMembersValuesItemBG {
    background: rgb(32, 25, 65);
  }
  .matchStatisticRoundBg {
    background-color: rgb(43, 36, 74, 0.5);
  }
  .matchRegisterBg {
    background-color: rgba(43, 36, 74, 0.5);
  }
  .resultMatchWrapperBg {
    background: rgb(43, 36, 74, 0.5);
  }
  .resultMatchButtonBg {
    background-color: #f6a020;
  }
  .matchlabelBg {
    background: rgb(43, 36, 74, 0.5);
  }
  .gameSwitcherBg {
    background: #2b244a;
    box-shadow: 0 0 20px #140f2b;
  }
  .langeageSwitcherBg {
    background: #b9a9ff;
    box-shadow: 0 0 10px #b9a9ff;
  }
  .plusButtonBg {
    background: rgb(43, 36, 74, 0.5);
  }
  .checkMarkBg {
    background: #363053;
  }
  .checkMarkPlayerBg {
    background: #f6a020;
  }
  .registrTournamentInfoBg {
    background: rgb(32, 25, 65, 0.8);
    box-shadow: 0px 10px 10px rgba(32, 25, 66, 0.35);
  }
  .registerTournamentPlayersRowBg {
    background: rgb(32, 25, 65, 0.8);
  }
  .registerTournamentButtonBg {
    background: rgb(32, 25, 65, 0.9);
    box-shadow: 0px 10px 10px rgba(33, 33, 33, 0.35);
  }
  .cupParticipantsBg {
    background: rgb(43, 36, 74, 0.6);
  }
  .cupRulesBg {
    background: (43, 36, 74, 0.6);
  }
  .cupInfoBg {
    background: rgb(43, 36, 74);
  }
  .cupTopBg {
    background: #1f1941;
  }
  .cupTabBorder {
    border: solid 1px rgb(43, 36, 74);
  }
  .eventHeaderBg {
    background: rgb(32, 25, 65);
  }
  .eventHeaderButtonBg {
    background: #d5cbff;
  }
  .eventStatusColorBg {
    background: #f6a020;
  }
  .eventBodyBg {
    background: rgba(255, 255, 255, 0.05);
  }
  .communityTitleBg {
    background: rgb(32, 25, 65);
  }
  .communityHeaderButtonBg {
    background: #d5cbff;
  }
  .communityTabBg {
    background: rgb(32, 25, 65);
  }
  .followedTabBg {
    background: rgb(32, 25, 65);
  }
  .buttonHoverBg {
    &:hover,
    &:focus {
      background: #b09dfe;
    }
  }
  .leaderbordBg {
    background: rgb(32, 25, 65);
  }
  .play_offContainerBg {
    background: rgb(32, 25, 65);
  }
  .playerInfoBgAndBorder {
    background: rgb(43, 36, 74);
    border-bottom: 1px solid #f6a020;
  }
  .teamBlockBg {
    background: #f6a020;
  }
  .teamBlockBgLowerRound:after {
    border: 2px solid #f6a020;
    background: rgb(43, 36, 74);
  }
  .teamBlockBg:before {
    border: 2px solid #f6a020;
    background: rgb(43, 36, 74);
  }
  .teamsBlockBordersAndBg:before {
    border-top: 5px solid #f6a020;
    border-right: 5px solid #f6a020;
    border-bottom: 5px solid #f6a020;
    background: rgb(43, 36, 74);
  }
  .playerPontBg {
    background: rgb(32, 25, 65);
  }
  .filterMatchesBgAndBorder {
    background: rgb(32, 25, 65);
    border: 1px solid;
    border-radius: 3px;
  }
  .tournamentImageBg::after {
    background: linear-gradient(
      90deg,
      #2b244a 0,
      rgba(43 36 74 / 93%) 15%,
      rgba(255, 255, 255, 0) 85%
    );
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .bracketTableFFAColors {
    background: rgb(43, 36, 74);
    tr {
      border-bottom: 4px solid rgb(32, 25, 65);
    }
    td {
      background: rgba(255, 255, 255, 0.05);
    }
    th {
      background: #2b244a;
    }
    input {
      background: rgb(43, 36, 74);
      color: #f6a020;
    }
  }
  .bracketFFANameHover {
    &:hover {
      color: #f6a020;
    }
  }

  .sendResultButtonFFABg {
    background: #f6a020;
    &:hover {
      background: #f6a020;
    }
  }
}
///////////////////////////////////////////////////////////////////////////
.hogasia {
  :root {
    --main-bg-color: #000000;
    --main-text-color: rgb(154, 156, 166);
    --main-title-text: #f2702c;
    --main-text--active: #9a9ca6;
    --main-non-active: #6c6d74;
    --main-active: #9a9ca6;
    --full-height-without-menu: calc(100vh - 80px);
    --menu-height: 20px;
    --footer-back: #1c1c1c;
    position: relative;
  }
  .transparentBg {
    background: rgba(255, 255, 255, 0.05);
  }
  .logoAuth {
    content: url("./assets/images/logoHogasia.jpg");
    max-width: 140px;
  }
  .headerLogoImageSm {
    content: url("./assets/images/logoHogasia.jpg");
  }
  .headerLogoImageLg {
    content: url("./assets/images/logoHogasia.jpg");
  }
  .bgGradient {
    background: linear-gradient(
      110deg,
      rgba(38, 34, 35, 1) 0%,
      rgba(191, 140, 44, 1) 100%
    );
  }
  .whiteBorder {
    border: 1px solid #ffffff;
  }
  .whiteText {
    color: #ffffff;
  }
  .mainColorfulText {
    color: #eda211;
  }
  .contentBoxColor {
    background: rgb(28, 28, 28);
  }
  .userAboutColor {
    color: #f6a020;
  }
  .headerBg {
    background: rgb(28, 28, 28);
  }
  .headerDropMainColor {
    background: rgb(28, 28, 28);
    li {
      background: rgba(255, 255, 255, 0.05);
    }
    li.active {
      a {
        color: #eda211;
      }
    }
    a {
      color: #fff;

      &:hover,
      &:focus {
        color: #eda211;
      }
    }
  }
  .headerNavLinksColor {
    li.active {
      a {
        color: #eda211;
      }
    }

    a {
      color: #fff;

      &:hover,
      &:focus {
        color: #eda211;
      }
    }
  }
  .footerBg {
    background: rgb(28, 28, 28);
  }

  .settingsMainTitleBg {
    background-color: #343435;
  }
  .settingsTitleBg {
    // background-color: #343435;
  }

  .reactSelectClors {
    border: 1px solid rgba(191, 140, 44, 0.38);
    .react-dropdown-select-dropdown {
      background: #1c1c1c;
      border-color: rgba(191, 140, 44, 0.38);
    }
    .react-dropdown-select-option {
      background: #1c1c1c;

      &-label {
        background: #1c1c1c;

        font-size: 12px;
        white-space: nowrap;
      }

      &-remove {
        background: #1c1c1c;
      }
    }
    span {
      background: #1c1c1c;
    }
    &:hover,
    &:focus,
    &:focus-within {
      border-color: #eda211;
    }
  }
  .reactSelectAuthColors {
    .react-dropdown-select-item {
      &:hover {
        background: rgb(202 155 47);
      }

      &.react-dropdown-select-item-selected {
        background: #f6a020;
      }
    }
  }
  .styledInputColor {
    border: 1px solid rgba(191, 140, 44, 0.38);
  }

  .calendarItemBorder {
    border: 1px solid rgba(191, 140, 44, 0.38);
  }

  .matchItemBg {
    background: #333;
    box-shadow: 0 12px 13px 0px #00000075;
    &:hover {
      box-shadow: 0 12px 13px 0px rgba(0, 0, 0, 0.7);
    }
  }
  .matchInfoScoreboardBg {
    background: rgb(28, 28, 28);
  }

  .discordContainer {
    background: rgb(28, 28, 28);
  }

  .discordButton {
    background: #313131;
  }
  .matchInfoStatusBg {
    background: #f6a020;
    color: #000000;
    font-weight: 600;
  }
  .matchInfoLoserColor {
    color: #f6a020;
  }
  .matchInfoProBg {
    background: #f6a020;
  }
  .matchMembersWrapperBg {
    background-color: #1c1c1c;
  }
  .matchMembersValuesItemBG {
    background: #313131;
  }
  .matchStatisticRoundBg {
    background-color: #1c1c1c;
  }
  .matchRegisterBg {
    background-color: #1c1c1c;
  }
  .resultMatchWrapperBg {
    background-color: #1c1c1c;
  }
  .resultMatchButtonBg {
    background-color: #f6a020;
  }
  .matchlabelBg {
    background-color: #1c1c1c;
  }
  .gameSwitcherBg {
    background: #1c1c1c;
    box-shadow: 0 0 10px rgba(191, 140, 44, 0.38);
  }
  .langeageSwitcherBg {
    background: rgb(202 155 47);
    box-shadow: 0 0 10px rgb(0, 0, 0);
  }
  .plusButtonBg {
    background: #313131;
  }
  .checkMarkBg {
    background: #1c1c1c;
  }
  .checkMarkPlayerBg {
    background: rgb(202 155 47);
  }
  .registrTournamentInfoBg {
    background: #1c1c1c;
    box-shadow: 0px 10px 10px rgb(159 144 30 / 76%);
  }
  .registerTournamentPlayersRowBg {
    background: #1c1c1c;
  }
  .registerTournamentButtonBg {
    background: #1c1c1c;
    box-shadow: 0px 10px 10px rgb(159 144 30 / 76%);
  }
  .cupParticipantsBg {
    background: #1c1c1c;
  }
  .cupRulesBg {
    background: #1c1c1c;
  }
  .cupInfoBg {
    background: #1c1c1c;
  }
  .cupTopBg {
    background: #1c1c1c;
  }
  .cupTabBorder {
    border: solid 1px rgba(191, 140, 44, 0.38);
  }
  .eventHeaderBg {
    background: #1c1c1c;
  }
  .eventHeaderButtonBg {
    background-color: #f6a020;
  }
  .eventStatusColorBg {
    background: #f6a020;
  }
  .eventBodyBg {
    background: #1c1c1c;
  }
  .communityTitleBg {
    background: #1c1c1c;
  }
  .communityHeaderButtonBg {
    background-color: #f6a020;
  }
  .communityTabBg {
    background: #1c1c1c;
  }
  .followedTabBg {
    background: #1c1c1c;
  }
  .buttonHoverBg {
    &:hover,
    &:focus {
      background: rgb(202 155 47);
    }
  }
  .leaderbordBg {
    background: #1c1c1c;
  }
  .play_offContainerBg {
    background: #1c1c1c;
  }
  .playerInfoBgAndBorder {
    background: #313131;
    border-bottom: 1px solid #f6a020;
  }
  .teamBlockBg {
    background: #f6a020;
  }
  .teamBlockBgLowerRound:after {
    border: 2px solid #f6a020;
    background: #313131;
  }
  .teamBlockBg:before {
    border: 2px solid #f6a020;
    background: #313131;
  }
  .teamsBlockBordersAndBg:before {
    border-top: 5px solid #f6a020;
    border-right: 5px solid #f6a020;
    border-bottom: 5px solid #f6a020;
    background: #313131;
  }
  .playerPontBg {
    background: #1c1c1c;
  }
  .filterMatchesBgAndBorder {
    background: #1c1c1c;
    border: 1px solid;
    border-radius: 3px;
  }
  .tournamentImageBg::after {
    background: linear-gradient(
      90deg,
      #333333 0,
      #333333f7 15%,
      rgba(255, 255, 255, 0) 85%
    );
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .bracketTableFFAColors {
    background: #1c1c1c;
    tr {
      border-bottom: 4px solid #313131;
    }
    td {
      background: rgba(255, 255, 255, 0.05);
    }
    th {
      background: #1c1c1c;
    }
    input {
      background: #1c1c1c;
      color: #f6a020;
    }
  }
  .bracketFFANameHover {
    &:hover {
      color: #f6a020;
    }
  }

  .sendResultButtonFFABg {
    background: #f6a020;
    &:hover {
      background: #f6a020;
    }
  }
}
