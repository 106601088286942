@mixin width1200px {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin width800px {
  @media (max-width: 800px) {
    @content;
  }
}
@mixin width600px {
  @media (max-width: 600px) {
    @content;
  }
}
@mixin width450px {
  @media (max-width: 450px) {
    @content;
  }
}

.content {
  font-size: 24px;
  padding-bottom: 10px;
  font-weight: bold;
  margin-top: 20px;
  line-height: 24px;
  @include width1200px {
    font-size: 20px;
  }
  @include width800px {
    font-size: 16px;
  }
  @include width600px {
    font-size: 14px;
  }
}
.supportUrl {
  font-weight: bold;
  cursor: pointer;
  color: #ffa901;
}
.wentWrong {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wentWrong p {
  color: #ffa901;
  font-weight: bold;
  font-size: 24px;
  @include width450px {
    font-size: 18px;
  }
}
.img {
  width: 400px;
  height: 300px;
  @include width450px {
    width: 300px;
    height: 200px;
  }
}
.sb-title {
  position: relative;
  top: -12px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
}

.sb-title-icon {
  position: relative;
  top: -5px;
}

.card-container {
  display: flex;
  height: 500px;
  width: 600px;
}

.panel {
  background: white;
  width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.half-input-container {
  display: flex;
  justify-content: space-between;
}

.half-input {
  max-width: 120px;
}

.map {
  width: 300px;
}

h2 {
  margin: 0;
  font-family: Roboto, sans-serif;
}

input {
  height: 30px;
}

input {
  border: 0;
  border-bottom: 1px solid black;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
}

input:focus::placeholder {
  color: #ffa901;
}

.button-cta {
  height: 40px;
  width: 40%;
  background: #3367d6;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.48);
  cursor: pointer;
}
