@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

:root {
  --main-bg-color: #000000;
  --main-text-color: rgb(154, 156, 166);
  --main-title-text: #f2702c;
  --main-text--active: #9a9ca6;
  --main-non-active: #6c6d74;
  --main-active: #9a9ca6;
}

div,
p,
ul,
li,
a,
p,
div,
h3,
h2,
h4,
img {
  padding: 0;
  margin: 0;
}

/* body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Oxygen", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  background: var(--main-bg-color);
  background: #3f317c;
  color: var(--main-text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 15px;
  height: 25px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: -o-linear-gradient(45deg, #2a1f21, #191518);
  background: linear-gradient(45deg, #2a1f21, #191518);
}

.header__time {
  font-size: 10px;
  color: #ffffff;
}

.header__mobile-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 65px;
}

.login-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 15px;
  background: -o-linear-gradient(135deg, #ab461f, #000000);
  background: linear-gradient(-45deg, #ab461f, #000000);
  height: 160px;
}

/* .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 10%;
  background: var(--main-bg-color);
  color: var(--main-title-text);
  text-transform: uppercase;
  text-align: center;
} */

.title__text {
  font-weight: 500;
  line-height: 26px;
}

.login {
  padding: 20px 10%;
  background: var(--main-bg-color);
  color: var(--main-title-text);
}

.login__login-and-sign-up {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 180px;
  margin: 25px auto;
}

.login-and-sign-up__login,
.login-and-sign-up__sign-up {
  margin: 0 15px;
}

.login__text,
.sign-up__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
}

.login__text:hover,
.sign-up__text:hover {
  color: var(--main-text--active);
  opacity: 0.5;
}

.login__text:active,
.sign-up__text:active {
  color: var(--main-title-text);
}

.login-and-sign-up__active p {
  color: var(--main-text--active);
}

.login-and-sign-up__active div {
  width: 68px;
  height: 3px;
  margin-top: 5px;
  background: url(../images/layer-10.png);
}

.login__form {
  margin: 10px 10%;
}

.form__login {
  border-bottom: 1px #18181a solid;
}

.form__login,
.form__password {
  position: relative;
}

.form__login img,
.form__password img {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 16px;
  left: 1px;
}

.form__password img {
  height: 20px;
  top: 12px;
}

.select__input {
  display: block;
  position: relative;
  width: 100%;
  height: 46px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 30px;
  border: none;
  color: var(--main-text-color);
  cursor: pointer;
}

.login__input {
  display: block;
  position: relative;
  width: 100%;
  height: 46px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 30px;
  border: none;
  background: transparent;
  color: var(--main-text-color);
  cursor: pointer;
}
.password__input {
  display: block;
  position: relative;
  width: 100%;
  height: 46px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 30px;
  border: none;
  background: transparent;
  color: var(--main-text-color);
  cursor: pointer;
}

.login__input::-webkit-input-placeholder,
.password__input::-webkit-input-placeholder {
  font-weight: 300;
  color: var(--main-text--active);
}

.login__input::-moz-placeholder,
.password__input::-moz-placeholder {
  font-weight: 300;
  color: var(--main-text--active);
}

.login__input:-ms-input-placeholder,
.password__input:-ms-input-placeholder {
  font-weight: 300;
  color: var(--main-text--active);
}

.login__input::-ms-input-placeholder,
.password__input::-ms-input-placeholder {
  font-weight: 300;
  color: var(--main-text--active);
}

.login__input::placeholder,
.password__input::placeholder {
  font-weight: 300;
  color: var(--main-text--active);
}

.login__input:hover::-webkit-input-placeholder,
.password__input:hover::-webkit-input-placeholder {
  color: var(--main-text--active);
}

.login__input:hover::-moz-placeholder,
.password__input:hover::-moz-placeholder {
  color: var(--main-text--active);
}

.login__input:hover:-ms-input-placeholder,
.password__input:hover:-ms-input-placeholder {
  color: var(--main-text--active);
}

.login__input:hover::-ms-input-placeholder,
.password__input:hover::-ms-input-placeholder {
  color: var(--main-text--active);
}

.login__input:hover::placeholder,
.password__input:hover::placeholder {
  color: var(--main-text--active);
}

.form__submit {
  display: block;
  width: 110px;
  height: 40px;
  margin: 0 auto;
  border-radius: 20px;
  border: 1px var(--main-title-text) solid;
  color: var(--main-title-text);
  background: transparent;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
}

.form__submit:hover {
  color: var(--main-text-color);
  border-color: var(--main-text-color);
}

.form__submit:active {
  color: var(--main-title-text);
  border-color: var(--main-title-text);
}

.form__question {
  display: block;
  margin: 15px auto;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: var(--main-non-active);
  border: none;
  background: transparent;
  cursor: pointer;
}

.form__question:hover {
  color: var(----main-title-text);
}

.form__question:active {
  color: var(--main-text--active);
}

.login__login-with {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 65px 10%;
  margin-bottom: 95px;
  border-bottom: 3px url(../images/layer-10.png) solid;
}

.login-with__title {
  margin-right: 20px;
  color: var(--main-non-active);
  font-size: 12px;
}

.login-with__facebook img,
.login-with__google img {
  cursor: pointer;
  border-radius: 50%;
}

.login-with__facebook:hover,
.login-with__google:hover {
  opacity: 0.5;
}

.login-with__facebook:active,
.login-with__google:active {
  opacity: 1;
  background: var(--main-text--active);
}

.login-with__decoration-1,
.login-with__decoration-2 {
  height: 2px;
  /*background: var(--main-text--active); */
  background-image: url("../images/gradient.jpg");
  margin: 0 6px;
  -webkit-box-flex: 77;
  -ms-flex-positive: 77;
  flex-grow: 77;
}

.footer__icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 140px;
  margin: 0 auto;
  padding: 20px 0;
}

.footer__icons .icons__square {
  width: 9px;
  height: 9px;
  background: #aaaaaa;
}

.footer__icons .icons__circle {
  position: relative;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000000;
  border: 1px #474747 solid;
}

.footer__icons .icons__circle:before {
  position: absolute;
  content: "";
  top: 2px;
  left: 2px;
  width: 6px;
  height: 6px;
  background: var(--main-text--active);
  border-radius: 50%;
  border: 2px #c1c1c1 solid;
}

.icons__triangle {
  border: 8px solid transparent;
  border-right: 8px solid var(--main-text--active);
}

.login__input-email ~ img {
  opacity: 0.3;
}

.checkboxes__checkbox {
  position: relative;
  margin: 25px 0;
}

.checkboxes__checkbox input {
  position: absolute;
  top: 0px;
  left: -3px;
  display: none;
}

.checkboxes__label {
  display: block;
  color: var(--main-text--active);
  font-size: 10px;
  line-height: 18px;
  padding-left: 15px;
  cursor: pointer;
}

.checkboxes__label::first-letter {
  padding-left: 0px;
}

.checkboxes__label:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  width: 6px;
  height: 7px;
  background: transparent;
  border: 2px var(--main-text--active) solid;
}

input:checked ~ .checkboxes__label:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 3px;
  width: 4px;
  height: 4px;
  background: var(--main-text--active);
}

.social__auth {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
