.wrapper {
  position: relative;

  .valueWrapper {
    display: flex;
    cursor: pointer;
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    margin-bottom: 15px;
    min-width: 240px;
    max-width: 240px;
    height: 40px;
    box-sizing: border-box;
    padding: 8px;
    align-items: center;
    justify-content: space-between;

    .value {
    }

    .icon {
    }
  }

  .selectOptionsWrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 7;
    border-radius: 15px;
    background: #6c6d74;
    max-height: 300px;
    overflow: scroll;

    .item {
      width: 100%;
      padding: 8px;
      font-size: 14px;
      box-sizing: border-box;
      cursor: pointer;
      transition: all 0.3s;

      &.active {
        background: #5e5f62;
      }

      &:first-child {
        border-radius: 15px 15px 0 0;
      }

      &:last-child {
        border-radius: 0 0 15px 15px;
      }

      &:hover {
        background: #5e5f62;
      }
    }
  }
}
