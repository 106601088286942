.lowerContainer {
  display: flex;
  margin-bottom: 20px;
  position: relative;

  &::after {
    content: "";
    width: 100px;
    height: 4px;
    background: white;
    position: absolute;
    top: calc(50% + 25px);
    right: -4px;
    z-index: -1;
  }
}

.rowCenter {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center; ЛОМАЮ ДЕРЕВО И ДЕЛАЮ В СТОЛБЕЦ BRACKET
  width: 400px;
  padding: 40px 10px 0 10px;
  margin: 0 4px;
  flex: 1;
}

.roundRow {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-around; ЛОМАЮ ДЕРЕВО И ДЕЛАЮ В СТОЛБЕЦ BRACKET
  width: 400px;
  padding: 40px 10px 0 10px;
  margin: 0 4px;
  flex: 1;
}
.roundWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 4px;
}
.roundTitle {
  top: 0;
  width: 100%;
  height: 50px;
  background: rgba(255, 255, 255, 0.05);
  text-align: center;
  line-height: 50px;
  font-size: 20px;
}

.teamsBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  width: 100%;
  cursor: pointer;

  &.teamsBlockWithGroup {
    &::before {
      display: none;
    }

    .teamBlock {
      .borderArrow {
        left: auto;
        right: -60px;
        border: 3px solid white;
        background: none;
        height: 30px;
        border-top: 0;
        border-left: 0;
      }

      &:before {
        content: "";
        width: 200px;
        height: 5px;
        background: white;
        position: absolute;
        top: calc(50% - 2px);
        left: -200px;
        z-index: -1;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 5%;
    right: 15px;
    width: 300px;
    height: 80%;
    /* BRACKET ПОМЕНЯЛ ЦВЕТ */
    border-top: 5px solid #a03845;
    border-right: 5px solid #a03845;
    border-bottom: 5px solid #a03845;
    /* border-top: 5px solid lightgrey;
 border-right: 5px solid lightgrey;
 border-bottom: 5px solid lightgrey; */
    // background: #241d48; BRACKET ПОМЕНЯЛ ЦВЕТ
    background: #585859;
    z-index: 2;
  }
}

.teamsBlockOne {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 78px;
  width: 100%;
  height: 200px;

  &.teamsBlockWithGroup {
    .borderArrow {
      display: block;
      left: auto;
      right: -60px;
      border: 3px solid white;
      background: none;
      height: 30px;
      border-top: 0;
      border-left: 0;
      z-index: -1;
      top: calc(50% - 15px);
    }

    .teamBlock {
      &:before {
        display: block;
      }

      &:after {
        display: block;
      }
    }
  }

  .teamBlock {
    &:before {
      content: "";
      width: 300px;
      height: 70%;
      border: 2px solid #a03845;
      // border: 2px solid white;BRACKET ПОМЕНЯЛ ЦВЕТ
      right: 0;
      // background: #241d48; BRACKET ПОМЕНЯЛ ЦВЕТ
      background: #585859;
      position: absolute;
      left: -10px;
      top: 15%;
      z-index: 0;
    }

    &:after {
      content: "";
      width: 300px;
      height: 70%;
      border: 2px solid #a03845;
      // border: 2px solid white;BRACKET ПОМЕНЯЛ ЦВЕТ
      border-left: 0;
      // background: #241d48; BRACKET ПОМЕНЯЛ ЦВЕТ
      background: #585859;
      position: absolute;
      right: -10px;
      top: 15%;
      display: none;
      z-index: 0;
    }
  }

  .borderTeam {
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    border: 0;
    height: auto;
    top: calc(50% + 25px);

    &::before {
      height: 3px;
      border-bottom: 0;
    }

    &::after {
      height: 4px;
      border-bottom: 0;
      width: 80%;
      background: white;
      right: -4px;
      content: "";
      position: absolute;
      top: -2px;
    }
  }
  &.teamsBlockWithGroup {
    .teamBlock {
      &:before {
        content: "";
        width: 200px;
        height: 5px;
        background: white;
        position: absolute;
        top: calc(50% - 2px);
        left: -200px;
        z-index: -1;
      }
    }
  }
}

.borderArrow {
  position: absolute;
  width: 100%;
  left: -52px;
  height: 3px;
  background: white;
  z-index: -1;
  top: calc(50% - 15px);
}

.teamBlock {
  position: relative;
  width: 80%;
  background: #a03845;

  // background: #3f317c;
  margin-bottom: 20px;

  &:after {
    content: "";
    width: 100px;
    height: 70%;
    border: 2px solid #a03845;
    // border: 2px solid white;BRACKET ПОМЕНЯЛ ЦВЕТ
    border-left: 0;
    // background: #241d48; BRACKET ПОМЕНЯЛ ЦВЕТ
    background: #585859;
    position: absolute;
    right: -10px;
    top: calc(50% - 35%);
    z-index: 0;
  }

  &:before {
    content: "";
    width: 100px;
    height: 70%;
    border: 2px solid #a03845;
    // border: 2px solid white;BRACKET ПОМЕНЯЛ ЦВЕТ
    right: 0;
    // background: #241d48; BRACKET ПОМЕНЯЛ ЦВЕТ
    background: #585859;
    position: absolute;
    left: -10px;
    top: calc(50% - 35%);
    z-index: 0;
  }

  &:last-child:after {
    content: "";
  }
}

.teamBlockOne {
  padding-top: 40px;
  width: 80%;
}

.borderTeamFullHeight {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  border: 0;

  &:before {
    content: "";
    width: 100%;
    height: calc(260% - 70px);
    /* BRACKET ПОМЕНЯЛ ЦВЕТ */
    border-top: 5px solid #a03845;
    border-right: 5px solid #a03845;
    border-bottom: 5px solid #a03845;
    /* border-top: 5px solid lightgrey;
  border-right: 5px solid lightgrey;
  border-bottom: 5px solid lightgrey; */
    display: block;
    position: absolute;
    z-index: -1;
    right: 100px;
  }
}

.playerOrange {
  background: orange;
}
