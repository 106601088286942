ul,
li,
a,
p,
div,
h3,
h4,
img {
  padding: 0;
  margin: 0;
}

.events__banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  max-width: 900px;
  margin: 0 auto;
  min-height: 300px;
  background-size: cover !important;
}
.events__banner__image {
  position: absolute;
  top: 10px;
  left: 5%;
  border-radius: 10px;
}
.events__photo-box {
  width: 150px;
  height: 120px;
  border-radius: 24px;
  border: 1px solid var(--icon);
  background-color: rgba(255, 255, 255, 0.05);
}
.events__title {
  position: relative;

  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
  box-sizing: border-box;
  padding: 24px;
  max-width: 900px;
  margin: 0 auto;
}
.events__title__life {
  display: inline-block;
  padding: 6px 8px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.87);
  border-radius: 8px;
  margin-bottom: 10px;
}
.events__title__name {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 10px;
  margin-right: 25px;
}
.events__date__and__place {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.events__buttons__and__static {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.events__games-container {
  padding: 5px 5px;
  display: flex;
  gap: 30px;
  /* min-height: 150px; */
  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
}

.events__date__image {
  margin-right: 8px;
}
.events__date__text {
  margin: 0 10px 0 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.72);
}
.events__heart__abs {
  position: absolute;
  top: 23px;
  right: 23px;
  cursor: pointer;
}
.events__heart__abs__text {
  margin-top: 8px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.87);
}
.events__button {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  border-radius: 15px;
  cursor: pointer;
  width: 100px;
  align-items: center;
}
.events__button__text {
  color: black;
  font-size: 14px;
  padding-right: 10px;
}
.events__button__followed {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100px;
  align-items: center;
}
