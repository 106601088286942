.checkInContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  width: 100%;
  flex-direction: column;
  padding: 30px 16px;
  max-width: 900px;
  margin: 20px auto;

  .wrapper {
    margin-top: 20px;
    width: 100%;

    .error {
      color: red;
      text-align: center;
      margin-bottom: 10px;
      width: 100%;
    }
  }

  .button {
    width: 220px;
    height: 35px;
    background-color: #f6a020;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    transition: all 0.3s;
    border: 0;

    &:hover {
      opacity: 0.8;
    }
  }
}
