.rating__container {
  display: flex;
}
.leaderbord {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  color: white;
}
.leaderbord__game-filter {
  margin: 0px 0px 10px;
  padding: 10px 15px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
.leaderbord__game {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: rgb(213, 203, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.leaderbord__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 0;
  max-width: 800px;
  margin: 0 auto;
}
.leaderbord__title-text {
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
}
.leaderbord__title-arrow {
  position: relative;
  cursor: pointer;
  width: 28px;
  height: 28px;
}
.title-arrow__strike {
  width: 28px;
  height: 2px;
  margin-top: 13px;
  background: white;
}

.title-arrow__strike:before {
  content: "";
  position: absolute;
  top: 19px;
  left: 0;
  width: 15px;
  height: 2px;
  background: white;
  transform: rotate(45deg);
}
.title-arrow__strike:after {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 15px;
  height: 2px;
  background: white;
  transform: rotate(-45deg);
}

.leaderbord__decoration-bell {
  position: relative;
  top: 0;
  right: 0;
}
.leaderbord__title-wrapper {
  border-bottom: 1px solid #9a9ca6;
}
.leaderbord__form-competition-rank {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px 0;
  max-width: 800px;
  margin: 0 auto;
}
.form-competition-rank__input {
  display: none;
}
.form-competition-rank__label {
  padding: 3px 5px;
  text-transform: uppercase;
  color: #808285;
  border-bottom: 1px transparent solid;
  font-family: "Montserrat";
  font-size: 20px;
  line-height: 22px;
}
.form-competition-rank__input:checked + .form-competition-rank__label {
  color: #ffffff;
  border-bottom-color: #f2702c;
}
.leaderbord__form-game-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 800px;
  padding: 10px 0 20px 0;
  margin: 0 auto;
  margin-bottom: 25px;
  overflow-x: scroll;
  scrollbar-width: thin;
  scrollbar-color: #f2702c transparent;
}
.leaderbord__form-game-list::-webkit-scrollbar {
  height: 4px;
  background-color: transparent;
}
.leaderbord__form-game-list::-webkit-scrollbar-thumb {
  height: 4px;
  background-color: #f2702c;
  border-radius: 2px;
}
.form-game__input {
  display: none;
}
.form-game__label {
  display: block;
  width: 90px;
  padding: 9px 0;
  border-radius: 25px;
  box-sizing: border-box;
  border: 2px white solid;
  background: transparent;
  color: #6d6e71;
  font-size: 10px;
  line-height: 11px;
  margin-right: 15px;
  text-align: center;
  font-family: "Montserrat";
  cursor: pointer;
}
.form-game__input:checked + .form-game__label {
  background: #bcbec0;
  color: #000000;
}
.leaderbord__result-list {
  height: var(--full-height-without-menu);
  /* max-height: 600px; */
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #f2702c transparent;
}
.leaderbord__result-list::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
.leaderbord__result-list::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: #f2702c;
  border-radius: 2px;
}

.leaderbord__result-list-item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  background: #9a9ca6;
  color: #939598;
  padding: 11px 0;
  text-align: center;
  font-family: "Montserrat";
}
.leaderbord__result-list-item--transparent {
  background: transparent;
}
.leaderbord__result-list-item--gray {
  background: rgba(255, 255, 255, 0.15);
}
.leaderbord__result-list-item--also-gray {
  background: rgba(255, 255, 255, 0.05);
}
.leaderbord__result-team {
  width: 30%;
  text-align: left;
  padding-left: 18px;
  box-sizing: border-box;
}
.leaderbord__result-pts,
.leaderbord__result-win,
.leaderbord__result-lose,
.leaderbord__result-percent,
.leaderbord__result-flag {
  width: 10%;
  color: #939598;
  font-family: "Montserrat";
  font-weight: bold;
}
.leaderbord__result-infos-link {
  width: 20%;
  font-weight: 500;
}
.leaderbord__result-place,
.leaderbord__result-emblem {
  width: 7.5%;
  font-weight: 600;
}
.leaderbord__result-tag {
  width: 15%;
  text-align: left;
  font-weight: 500;
}

.leaderbord__result-list-item--header {
  color: white;
  background: rgba(255, 255, 255, 0.25);
}
.leaderbord__result-list-item .leaderbord__result-infos-link {
  color: white;
}
.leaderbord__result-list-item .leaderbord__result-tag {
  color: white;
}
.leaderbord__result-list {
  height: var(--full-height-without-menu);
  /* max-width: 800px; */
  margin: 0 auto;
}
.id-first {
  color: #f4b71e;
}
.id-second {
  color: #bcbec0;
}
.id-third {
  color: #cc8d42;
}
.leaderbord__result-list-item--header p {
  color: white;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
}
@media (max-width: 1000px) {
  .rating__container {
    display: flex;
    flex-direction: column-reverse;
  }
  .leaderbord__title {
    padding: 26px 5%;
    box-sizing: border-box;
  }
  .leaderbord__form {
    padding: 0 5%;
    box-sizing: border-box;
  }
  .leaderbord__result-list {
    box-sizing: border-box;
  }
}
@media (max-width: 600px) {
  .leaderbord__result-list {
    font-size: 14px;
    line-height: 14px;
  }
  .form-competition-rank__label {
    font-size: 18px;
    line-height: 18px;
  }
  .leaderbord__result-tag {
    padding-left: 5px;
    box-sizing: border-box;
  }
  .leaderbord__result-list-item--header p {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (max-width: 450px) {
  .leaderbord__result-list {
    font-size: 12px;
    line-height: 12px;
  }
  .leaderbord__result-emblem {
    width: 10%;
  }
  .leaderbord__result-tag {
    width: 12.5%;
  }
  .form-competition-rank__label {
    font-size: 14px;
    line-height: 14px;
  }
  .leaderbord__result-list-item--header p {
    font-size: 14px;
    line-height: 14px;
  }
}
@media (max-width: 350px) {
  .leaderbord__result-list {
    font-size: 10px;
    line-height: 10px;
  }
  .form-competition-rank__label {
    font-size: 13px;
    line-height: 13px;
  }
}
