/* This stylesheet generated by Transfonter (https://transfonter.org) on February 9, 2018 12:28 AM */

@font-face {
    font-family: 'Nexa Thin';
    src: url('Nexa-Thin-Italic.eot');
    src: local('Nexa Thin Italic'), local('Nexa-Thin-Italic'),
        url('Nexa-Thin-Italic.eot?#iefix') format('embedded-opentype'),
        url('Nexa-Thin-Italic.woff') format('woff'),
        url('Nexa-Thin-Italic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa';
    src: url('NexaRegular.eot');
    src: local('Nexa Regular'), local('NexaRegular'),
        url('NexaRegular.eot?#iefix') format('embedded-opentype'),
        url('NexaRegular.woff') format('woff'),
        url('NexaRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa';
    src: url('Nexa-Light.eot');
    src: local('Nexa Light'), local('Nexa-Light'),
        url('Nexa-Light.eot?#iefix') format('embedded-opentype'),
        url('Nexa-Light.woff') format('woff'),
        url('Nexa-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa';
    src: url('NexaBlack.eot');
    src: local('Nexa Black'), local('NexaBlack'),
        url('NexaBlack.eot?#iefix') format('embedded-opentype'),
        url('NexaBlack.woff') format('woff'),
        url('NexaBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa Book';
    src: url('Nexa-Book.eot');
    src: local('Nexa Book'), local('Nexa-Book'),
        url('Nexa-Book.eot?#iefix') format('embedded-opentype'),
        url('Nexa-Book.woff') format('woff'),
        url('Nexa-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa XBold';
    src: url('Nexa-XBold-Italic.eot');
    src: local('Nexa XBold Italic'), local('Nexa-XBold-Italic'),
        url('Nexa-XBold-Italic.eot?#iefix') format('embedded-opentype'),
        url('Nexa-XBold-Italic.woff') format('woff'),
        url('Nexa-XBold-Italic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa';
    src: url('NexaHeavy.eot');
    src: local('Nexa Heavy'), local('NexaHeavy'),
        url('NexaHeavy.eot?#iefix') format('embedded-opentype'),
        url('NexaHeavy.woff') format('woff'),
        url('NexaHeavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa';
    src: url('Nexa-Bold.eot');
    src: local('Nexa Bold'), local('Nexa-Bold'),
        url('Nexa-Bold.eot?#iefix') format('embedded-opentype'),
        url('Nexa-Bold.woff') format('woff'),
        url('Nexa-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa Regular';
    src: url('Nexa-Regular-Italic.eot');
    src: local('Nexa Regular Italic'), local('Nexa-Regular-Italic'),
        url('Nexa-Regular-Italic.eot?#iefix') format('embedded-opentype'),
        url('Nexa-Regular-Italic.woff') format('woff'),
        url('Nexa-Regular-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa Heavy';
    src: url('Nexa-Heavy-Italic.eot');
    src: local('Nexa Heavy Italic'), local('Nexa-Heavy-Italic'),
        url('Nexa-Heavy-Italic.eot?#iefix') format('embedded-opentype'),
        url('Nexa-Heavy-Italic.woff') format('woff'),
        url('Nexa-Heavy-Italic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa Black';
    src: url('Nexa-Black-Italic.eot');
    src: local('Nexa Black Italic'), local('Nexa-Black-Italic'),
        url('Nexa-Black-Italic.eot?#iefix') format('embedded-opentype'),
        url('Nexa-Black-Italic.woff') format('woff'),
        url('Nexa-Black-Italic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa Bold';
    src: url('Nexa-Bold-Italic.eot');
    src: local('Nexa Bold Italic'), local('Nexa-Bold-Italic'),
        url('Nexa-Bold-Italic.eot?#iefix') format('embedded-opentype'),
        url('Nexa-Bold-Italic.woff') format('woff'),
        url('Nexa-Bold-Italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa';
    src: url('NexaThin.eot');
    src: local('Nexa Thin'), local('NexaThin'),
        url('NexaThin.eot?#iefix') format('embedded-opentype'),
        url('NexaThin.woff') format('woff'),
        url('NexaThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa XBold';
    src: url('Nexa-XBold.eot');
    src: local('Nexa XBold'), local('Nexa-XBold'),
        url('Nexa-XBold.eot?#iefix') format('embedded-opentype'),
        url('Nexa-XBold.woff') format('woff'),
        url('Nexa-XBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa Book';
    src: url('Nexa-Book-Italic.eot');
    src: local('Nexa Book Italic'), local('Nexa-Book-Italic'),
        url('Nexa-Book-Italic.eot?#iefix') format('embedded-opentype'),
        url('Nexa-Book-Italic.woff') format('woff'),
        url('Nexa-Book-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Nexa Light';
    src: url('Nexa-Light-Italic.eot');
    src: local('Nexa Light Italic'), local('Nexa-Light-Italic'),
        url('Nexa-Light-Italic.eot?#iefix') format('embedded-opentype'),
        url('Nexa-Light-Italic.woff') format('woff'),
        url('Nexa-Light-Italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
