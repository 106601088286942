.discordButton {
  display: flex;
  align-items: center;
  background: #313131;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
  font-weight: 600;
  width: 280px;
  margin: 20px auto;
  cursor: pointer;
  transition: all 0.3s;
  justify-content: center;
  text-align: center;

  &.animationButton {
    -webkit-animation: blinker 4s infinite;
    animation: blinker 1.2s infinite;
    box-shadow: 0 0 3px #f6a020;
  }

  .textContainer {
    display: flex;
    flex-direction: column;

    .caption {
      font-size: 12px;
    }
  }

  svg {
    width: 40px;
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.match {
  .hierarchy {
    max-width: 900px;
    padding: 30px 0;
    margin: 0 auto;
    font-size: 12px;
  }

  .info {
    max-width: 900px;
    padding: 20px 0;
    margin: 0 auto;
    font-size: 14px;
    display: flex;
  }

  .labelBeforeRegistration {
    position: relative;
    max-width: 900px;
    margin: 20px auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    width: 100%;
    flex-direction: column;
    padding: 30px 16px;
  }

  .description {
    line-height: 1.2;
    font-size: 16px;
  }

  .teamsName {
    span {
      margin: 0;
      font-size: 26px;
      font-weight: 400;
      line-height: 1.6;
    }
  }

  .logo {
    width: 50px;
    height: 50px;
    border-radius: 30px;
    margin-right: 15px;
  }

  .discussion {
    position: relative;
    max-width: 900px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0;
  }

  .discordContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 900px;
    margin: 0 auto;
    background-color: #1c1c1c;
    border-radius: 18px;

    .buttons {
      display: flex;
      align-items: stretch;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 40px;
      svg {
        width: 80px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .match {
    .discordContainer {
      .buttons {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@-webkit-keyframes blinker {
  50% {
    box-shadow: 0 0 4px #f6a020;
  }
}

@keyframes blinker {
  50% {
    box-shadow: 0 0 5px #f6a020;
  }
}
