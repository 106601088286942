.event {
  position: relative;
  width: 420px;
  height: 85px;
  border-radius: 13px;
  margin: 0 auto 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px;
  border-radius: 5px;
  cursor: pointer;
}
.event__star {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.eventLogo {
  width: 58px;
  height: 58px;
  border-radius: 13px;
  object-fit: contain;
}
.eventDescription {
  flex-grow: 77;
  color: #fff;
}

.eventName {
  text-align: center;
  color: #fff;
  font-size: 15px;
  line-height: 15px;
}

.eventDecoration {
  width: 166px;
  height: 1px;
  margin: 4px auto 8px;
  background: #9a9ca6;
}

.eventDate {
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
}
@media (max-width: 425px) {
  .event {
    width: 320px;
  }
}
