.play_offContainer {
  /* width: 900px; */
  display: flex;
  /* ДОБАВИЛ БЕКГРАУНД BRACKET */
  /* height: 100%; */
  /* ПОМЕНЯЛ ДЛИННУ BRACKET */
  max-height: 1000px;
  margin: 18px 0;
  padding-bottom: 18px;
  cursor: grab;
  overflow-x: scroll;
}
.play_offFlexContainer {
  display: flex;
  margin-bottom: 20px;
  position: relative;
}
.play_offFlexContainer::after {
  content: "";
  width: 100px;
  height: 4px;
  background: white;
  position: absolute;
  top: calc(50% - 5px + 28px);
  right: 0;
  z-index: -1;
}
.play_offRoundRow {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; ЛОМАЮ ДЕРЕВО И ДЕЛАЮ В СТОЛБЕЦ BRACKET*/
  width: 400px;
  padding: 40px 10px 0 10px;
  margin: 0 4px;
}

.play_offRoundRowCenter {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; ЛОМАЮ ДЕРЕВО И ДЕЛАЮ В СТОЛБЕЦ BRACKET*/
  width: 400px;
  padding: 40px 10px 0 10px;
  margin: 0 4px;
}

.play_offRoundRowCenterFiction {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  padding: 40px 0 0 0;
  margin: 0;
}
.play_offRoundRow2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 0 0 400px;
}

/* УБРАЛ ЛИНИИ ВНУТРИ BRACKET */
/* .play_offRoundRow2::before {
  content: "";
  left: 0;
  position: absolute;
  height: calc(50% + 6px);
  top: calc(60% - 253px);
  border-left: 4px solid white;
  width: 4px;
} */

.play_offRoundRow2::after {
  content: "";
  left: 0;
  position: absolute;
  height: 4px;
  top: calc(50% - 50px + 48px);
  background: white;
  width: 100%;
  z-index: -1;
}
/* //////////////////////////////////////////////////////////////////////// */
.roundTitle {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50px;
  /* background: #333333; */
  background: rgba(255, 255, 255, 0.05);
  text-align: center;
  line-height: 50px;
  font-size: 20px;
}
.teamsBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
  width: 100%;
  cursor: pointer;
}
/*.teamsBlock:before,*/
/*.teamBlock2:before {*/
.teamsBlock:before {
  content: "";
  position: absolute;
  top: 5%;
  right: 15px;
  width: 300px;
  height: 90%;
  /* BRACKET ПОМЕНЯЛ ЦВЕТ */
  /* border-top: 5px solid lightgrey;
  border-right: 5px solid lightgrey;
  border-bottom: 5px solid lightgrey; */
  /* background: #241d48; BRACKET ПОМЕНЯЛ ЦВЕТ */
  z-index: 0;
}
/*.teamBlock2:before {*/
/*  top: -125px;*/
/*  left: -540px;*/
/*  height: 438px;*/
/*  width: 510px;*/
/*}*/

.borderTeam {
  /* width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  border: 0; */
}
.borderTeamFullHeight {
  /* width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  border: 0; */
}
.teamsBlock_forOne .borderTeam {
  height: auto;
  top: calc(50% + 25px);
}
.teamsBlock_forOne .borderTeam:before {
  height: 3px;
  border-bottom: 0;
}
.teamsBlock_forOne .borderTeam:after {
  height: 4px;
  border-bottom: 0;
  width: 80%;

  /* background: white; УБРАЛ ПОЛОСКУ BRACKET */
  right: -4px;
  content: "";
  position: absolute;
  top: -2px;
}
.borderTeam:before {
  /* content: "";
  width: 100%;
  height: calc(100% - 70px);
  border-top: 5px solid lightgrey;
  border-right: 5px solid lightgrey;
  border-bottom: 5px solid lightgrey;
  display: block;
  position: absolute;
  z-index: -1;
  right: 100px; */
}

.borderTeamFullHeight:before {
  /* content: "";
  width: 100%;
  height: var(--height);
  border-top: 5px solid lightgrey;
  border-right: 5px solid lightgrey;
  border-bottom: 5px solid lightgrey;
  display: block;
  position: absolute;
  z-index: -1;
  right: 100px; */
}

.teamsBlockOneFiction {
  height: 4px;
  z-index: -1;
  width: 100%;
  position: absolute;
  top: calc(50% - 5px + 28px);
  width: 100%;
  left: 0;
  background: white;
}
/* @media screen and (max-width: 1446px){
  .teamsBlock:before{
     right: 0px;
  }
} */
.teamsBlock_forOne {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; СДЕЛАЛ В РЯД BRACKET*/
  /* margin-top: 78px; BRACKET */
  margin-top: 28px;
  width: 100%;
  height: 200px;
}
.teamBlock {
  transition: all 0.3s;
  position: relative;
  width: 80%;
  /* background: #a03845; */
  /* background: #3f317c; ПОМЕНЯЛ ЦВЕТ BRACKET*/
}
.teamBlock2 {
  margin-top: 75px;
  /* margin: auto 0 auto 50px; ПОМЕНЯЛ ОТСТУПЫ */
  position: relative;
  width: 80%;
  /* background: #a03845; */
  /* background: #3f317c; ПОМЕНЯЛ ЦВЕТ BRACKET*/
}
/*.teamBlock:after,*/
/*.teamBlock2:after {*/
/*  content: "";*/
/*  position: absolute;*/
/*  top: 48%;*/
/*  left: -104px;*/
/*  height: 5px;*/
/*  width: 104px;*/
/*  background: lightgrey;*/
/*  z-index: -2;*/
/*}*/
/*.play_offRoundRow2:after {*/
/*  left: -180px;*/
/*}*/
/* @media screen and (max-width: 1446px){
  .teamBlock:after{
     left: -85px;
  }
} */
.matchNumber {
  position: absolute;
  top: 35px;
  left: -28px;
  font-size: 18px;
  color: rgb(195, 195, 224);
}
.teamBlock:last-child {
  margin-top: 20px;
}
.teamBlock:last-child {
  margin-top: 20px;
}
.teamBlock:last-child:after {
  content: "";
}
.teamBlock_forOne {
  padding-top: 40px;
  width: 80%;
}
.player {
  transition: all 0.3s;

  display: flex;
  justify-content: space-between;
  height: 40px;
  padding-left: 10px;
  /* ДОБАВИЛ БОРДЕР BRACKET */
  /* background: rgb(43, 36, 74); */
  position: relative;
  z-index: 4;
}
.player:last-child {
  margin-top: 2px;
}
.playerTitle {
  line-height: 40px;
  font-size: 18px;
}
.playerPont {
  width: 40px;
  height: 100%;
  line-height: 40px;
  font-size: 18px;
  background: #6c6b6b;
  text-align: center;
}
.playerPont_orange {
  background: orange;
  font-weight: 400;
  color: black !important;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 0;
  width: 100%;
}

.roundChange {
  position: absolute;
  left: 50%;
  top: 69%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-left: -190px;
  padding: 15px;

  width: 350px;
  height: 270px;

  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  background: #563e87;
  z-index: 100;
}

.roundChangeInputTime {
  background: #9a1ec779;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  font-size: 18px;
  color: white;
  margin-bottom: 15px;
}
.roundChangeTitle {
  font-size: 18px !important;
  color: white !important;
  margin: 15px 0px;
}
.roundChangeSelect {
  margin-bottom: 25px;
  background: #9a1ec779;
  font-size: 18px !important;
  color: white !important;
  padding: 0 10px;
  border-radius: 5px;
}

.selectItem {
  background: #9a1ec7 !important;
}
.modal__wrapper {
  background: #1818180e;
  padding: 15px 15px 60px 15px;
  box-sizing: border-box;
}
.modal {
  width: 500px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 20px;
  border: 2px #707070 solid;
  ul {
    background: #b01717eb !important;
  }
}
.modalContainer {
  display: flex;
  flex-direction: column;
}
.modal__header__about {
  height: 70px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal__header__about__title {
  padding-left: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #ffffff;
}

.modal__title {
  display: block;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.modal__info {
  display: flex;
  flex-direction: column;
}
.modal__info__value {
  display: flex;
}
.modal__info__value__first {
  width: 125px;
  margin-left: 15px;
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  text-align: start;
}
.modal__info__value__second {
  width: 250px;
  margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  line-height: 30px;
  text-align: start;
}
.modal__title__header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.modal__title__buttons {
  display: flex;
  flex-direction: column;
}
.modalText {
  padding-top: 20px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.modalText:hover {
  color: orange;
}
@media (max-width: 768px) {
  .modal {
    width: 400px;
  }
}

@media (max-width: 425px) {
  .modal {
    width: 300px;
  }
}
