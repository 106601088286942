ul,
li,
a,
p,
div,
h3,
h4,
img {
  padding: 0;
  margin: 0;
}
.events__tournaments {
  max-width: 900px;
  margin: 0 auto;
  box-sizing: border-box;
}
.events__about__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 12px;
  text-align: center;
}
.events__cards {
  max-width: 900px;
  margin-top: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;
}
.event__list__events {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  list-style: none;
  width: 100%;
  padding: 0 18%;
  box-sizing: border-box;
  padding: 0;
}
.notification__pagination {
  height: 100px;
  margin-top: auto;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.tab__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1170px;
  width: 100%;

  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 40px;
    height: 50px;
    width: 170px;
    background: #ffffff;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #3d3177;
    transition: all 0.3s;

    &:hover,
    &.active {
      background: #ffa900;
    }
  }
}

@media (max-width: 767px) {
  .tab__wrapper {
    flex-wrap: wrap;

    .tab {
      padding: 20px 30px;
      margin: 10px;
    }
  }
}
