.menuWrapper {
  padding: 19px 18%;
}

.userInfo {
  padding: 5px 0 20px;
}

.listTeams {
  height: 100%;
  width: 100%;
  margin-left: 20%;
  display: none;
  visibility: hidden;
}

.open {
  display: block;
  visibility: visible;
}

.close {
  display: none;
  visibility: hidden;
}

.dropListItem,
.emptyList {
  display: block;
  padding: 10px;
  margin-left: 20px;
  color: #ffffff;
}

.emptyList {
  padding: 20px;
}

.buttonTeamTitle {
  padding: 0;
  margin: 0;
  position: relative;
}

.dropListLink {
  color: var(--main-title-text);
}

.titleArrow {
  filter: invert(92%) sepia(55%) saturate(1%) hue-rotate(152deg)
    brightness(104%) contrast(77%);
  position: absolute;
  transform: rotate(-90deg);
  left: 105%;
  top: 0;
  width: 15px;
  height: 15px;
  transition: all 0.1s ease-in-out;
}

.profileWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
}

.logoWrapper {
  position: relative;
  margin-right: 20px;
}

.profileDescription {
  text-align: center;
}

.name {
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 20px;
  word-break: break-all;
  line-height: 22px;
}

.email {
  margin-bottom: 10px;
  color: #ffffff;
  font-weight: 500;
}

.button {
  background: transparent;
  border: none;
  outline: none;
  padding: 2px 0;
  cursor: pointer;
  color: #ffffff;
  font-weight: 500;

  &:active {
    color: orange;
  }

  &:hover {
    opacity: 0.6;
  }
}

.menuItem {
  display: block;
  padding: 12px 0;
  border-top: 1px #6a6b6e solid;
  color: #ffffff;
  transition: all 0.3s;

  p {
    cursor: pointer;
    margin-right: 10px;

    &:hover {
      color: orange;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      color: orange;
    }
  }
}

.footer {
  display: block;
  padding: 25px;
  text-align: center;
  color: #ffffff;

  a {
    text-decoration: none;
    transition: all 0.3s;
    cursor: pointer;
    margin-right: 10px;

    &:hover {
      color: orange;
    }
  }
}

.logo {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

@media (max-width: 850px) {
  .openList {
    padding: 20px 5%;
  }

  .menuWrapper {
    padding: 20px 5%;
  }

  .profileWrapper {
    justify-content: flex-start;
  }

  .userInfo {
    padding: 5px 0 20px;
  }

  .menuItem {
    padding: 13px 5% 13px 0;
  }

  .dropListItem {
    padding: 10px;
    margin-left: 20px;
  }

  .profileDescription {
    text-align: left;

    h3 {
      font-size: 16px;
    }
  }

  .footer {
    padding: 0;
    margin-top: 30px;
  }

  .logo {
    width: 60px;
    height: 60px;
  }
}
