@mixin width1200px {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin width800px {
  @media (max-width: 800px) {
    @content;
  }
}
@mixin width600px {
  @media (max-width: 600px) {
    @content;
  }
}
@mixin width450px {
  @media (max-width: 450px) {
    @content;
  }
}
@mixin width320px {
  @media (max-width: 320px) {
    @content;
  }
}

.header p {
  color: #ffa901;
  font-weight: bold;
  font-size: 24px;
  padding-top: 15px;
  text-transform: uppercase;
  text-align: center;

  line-height: 1.5em;

  @include width450px {
    font-size: 18px;
  }
}
.header {
  a {
    color: #ffa901;
    font-weight: bold;
    font-size: 24px;
    padding-top: 15px;
    text-transform: uppercase;
    cursor: pointer;

    @include width450px {
      font-size: 18px;
    }
  }
}
.content {
  margin: 0 0 10px;
  background: rgb(43, 36, 74, 0.5);
  padding: 30px;
  border-radius: 5px;
}

.contentText {
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    color: #ffa901;
    font-weight: bold;
    font-size: 24px;
    padding-top: 15px;
    text-transform: uppercase;
    cursor: pointer;
    @include width450px {
      font-size: 18px;
    }
  }
}
.contentText p {
  color: #ffa901;
  font-weight: bold;
  font-size: 24px;
  padding-top: 15px;
  text-transform: uppercase;
  @include width450px {
    font-size: 18px;
  }
}
.img {
  width: 400px;
  border-radius: 5px;

  @include width450px {
    width: 250px;
  }
  @include width320px {
    width: 200px;
  }
}
