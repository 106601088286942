.styledCard {
  // width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
}

.styledCard__header {
  position: relative;
  width: 112px;
  height: 112px;
  border: 1px solid #818184;
  border-radius: 32px;
  //  background-color: #414042;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.styledCard__header__delete {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: rgb(32, 25, 65);
  border-radius: 10px;
  padding: 5px 5px;
  cursor: pointer;
}

.styledCard__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  width: 85px;
  height: 48px;
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
}

.styledCard__image {
  width: 112px;
  height: 112px;
  border-radius: 32px;
  object-fit: cover;
}
