ul,
li,
a,
p,
div,
h3,
h4,
img {
  padding: 0;
  margin: 0;
}
.events__about__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.87);
  margin-bottom: 12px;
  margin-top: 24px;
  margin-bottom: 12px;
}
.events__organizer__team {
  align-items: stretch !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;

  border-radius: 26px;
  overflow-x: auto;
}
.events__games__container {
  padding: 5px 5px;
  display: flex;
  gap: 30px;
  /* min-height: 150px; */
  overflow-y: auto;
  overflow-x: auto;
  white-space: nowrap;
}
.organizer__image {
  margin-right: 16px;
}
@media (min-width: 450px) {
  .organizer__image {
    width: 100px;
    height: 100px;
  }
}
