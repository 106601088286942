.wrapper {
  position: relative;
  max-width: 900px;
  margin: 20px auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 18px;
  width: 100%;
  flex-direction: column;
  padding: 30px 16px;

  .title {
    font-weight: 500;
    font-size: 15px;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.87);
  }

  .teams {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
  }

  .row {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    grid-gap: 30px;
    width: 100%;

    > div {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item {
      cursor: pointer;

      .avatarGamer {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .name {
    font-size: 24px;
    flex: 1 1;
    width: 100%;
    text-align: center;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .values {
    width: 100%;
    display: flex;
    flex-direction: column;

    .item {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      grid-gap: 40px;
      margin: 10px 0;
      padding: 10px;
      border-radius: 16px;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .icon {
        width: 20px;
      }
      .status {
      }

      .nickname {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .bold {
          font-weight: 700;
          font-size: 12px;
        }
      }

      .logo {
        max-width: 40px;
        width: 40px;
        height: 40px;

        img {
          border-radius: 50%;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .row {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .row {
    grid-template-columns: 1fr;
  }
}
