.modal__wrapper {
  background: #181818;
  padding: 15px 15px 60px 15px;
  box-sizing: border-box;
}
.modal {
  width: 500px;
  padding: 20px 12px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 20px;
  border: 2px #707070 solid;
}

.modal__image {
  display: block;
  margin: 0 auto;
  margin-bottom: 40px;
  width: 100px;
}

.modal__title {
  display: block;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.modal__title__header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.modal__title__buttons {
  display: flex;
  flex-direction: column;
  p {
    padding-top: 20px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  p:hover {
    color: orange;
  }
}
.support__button {
  color: #f7a01d;
  cursor: pointer;
}
@media (max-width: 768px) {
  .modal {
    width: 300px;
  }
}

@media (max-width: 350px) {
  .modal {
    width: 240px;
  }
  .modal__title__header {
    font-size: 16px;
  }
}
